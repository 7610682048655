import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { parse } from '../../util/urlHelpers';
import config from '../../config';

// ================ Action types ================ //

export const FETCH_CONSOLE_TRANSACTIONS_REQUEST =
  'app/ConsoleTransactionPage/FETCH_CONSOLE_TRANSACTIONS_REQUEST';
export const FETCH_CONSOLE_TRANSACTIONS_SUCCESS =
  'app/ConsoleTransactionPage/FETCH_CONSOLE_TRANSACTIONS_SUCCESS';
export const FETCH_CONSOLE_TRANSACTIONS_ERROR =
  'app/ConsoleTransactionPage/FETCH_CONSOLE_TRANSACTIONS_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchInProgress: false,
  fetchTransactionListError: null,
  pagination: null,
  transactionList: [],
};

export default function consoleTransactionsPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_CONSOLE_TRANSACTIONS_REQUEST:
      return { ...state, fetchInProgress: true, fetchTransactionListError: null };
    case FETCH_CONSOLE_TRANSACTIONS_SUCCESS: {
      return {
        fetchInProgress: false,
        transactionList: payload.data.data,
        pagination: payload.data.meta,
        fetchTransactionListError: null
      };
    }
    case FETCH_CONSOLE_TRANSACTIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        pagination: null,
        transactionList: [],
        fetchInProgress: false,
        fetchTransactionListError: payload
      };

    default:
      return state;
  }
}
// ================ Action creators ================ //

const fetchTransactionListRequest = () => ({ type: FETCH_CONSOLE_TRANSACTIONS_REQUEST });

const fetchTransactionListSuccess = response => ({
  type: FETCH_CONSOLE_TRANSACTIONS_SUCCESS,
  payload: response,
});

const fetchTransactionListError = e => ({
  type: FETCH_CONSOLE_TRANSACTIONS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const CONSOLE_PAGE_SIZE = 100;

export const loadData = (params, search) => async (dispatch, getState, sdk) => {
  const { page = 1, userId, processState, countryId = config.custom.countryId, nonDeliveredOnTime, phrase, voucherCode, sort, businessCategory } = parse(search);
  const paginationParams = {
    page,
    per_page: CONSOLE_PAGE_SIZE,
  };

  const businessCategoryParam = businessCategory ? { businessCategory } : undefined;

  const queryParams = {
    ...paginationParams,
    include: ['provider', 'user', 'listings'],
    userId,
    voucherCode,
    processState,
    countryId,
    nonDeliveredOnTime,
    phrase,
    sort,
    ...businessCategoryParam
  };

  dispatch(fetchTransactionListRequest());

  return await sdk.newSdk.transactions
    .query(queryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchTransactionListSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchTransactionListError(e));
      throw e;
    });
};
