import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { isTransactionsTransitionInvalidTransition, storableError } from '../../util/errors';
import {
  txIsEnquired,
  getReview1Transition,
  getReview2Transition,
  TRANSITION_ACCEPT,
  TRANSITION_DECLINE,
  TRANSITION_PICK_UP,
  TRANSITION_COMPLETE_NOT_PICKEDUP,
  TRANSITION_COMPLETE,
  TRANSITION_CANCEL_BY_PROVIDER,
} from '../../util/transaction';
import * as log from '../../util/log';
import {
  updatedEntities,
  denormalisedEntities,
  denormalisedResponseEntities,
} from '../../util/data';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUserNotifications } from '../../ducks/user.duck';
import { updateResourceType } from '../EditItemPage/EditItemPage.duck';

const { UUID } = sdkTypes;

const MESSAGES_PAGE_SIZE = 100;
const CUSTOMER = 'customer';

// ================ Action types ================ //

export const SET_INITAL_VALUES = 'app/TransactionPage/SET_INITIAL_VALUES';

export const FETCH_TRANSACTION_REQUEST = 'app/TransactionPage/FETCH_TRANSACTION_REQUEST';
export const FETCH_TRANSACTION_SUCCESS = 'app/TransactionPage/FETCH_TRANSACTION_SUCCESS';
export const FETCH_TRANSACTION_ERROR = 'app/TransactionPage/FETCH_TRANSACTION_ERROR';

export const FETCH_TRANSITIONS_REQUEST = 'app/TransactionPage/FETCH_TRANSITIONS_REQUEST';
export const FETCH_TRANSITIONS_SUCCESS = 'app/TransactionPage/FETCH_TRANSITIONS_SUCCESS';
export const FETCH_TRANSITIONS_ERROR = 'app/TransactionPage/FETCH_TRANSITIONS_ERROR';

export const ACCEPT_SALE_REQUEST = 'app/TransactionPage/ACCEPT_SALE_REQUEST';
export const ACCEPT_SALE_SUCCESS = 'app/TransactionPage/ACCEPT_SALE_SUCCESS';
export const ACCEPT_SALE_ERROR = 'app/TransactionPage/ACCEPT_SALE_ERROR';

export const DECLINE_SALE_REQUEST = 'app/TransactionPage/DECLINE_SALE_REQUEST';
export const DECLINE_SALE_SUCCESS = 'app/TransactionPage/DECLINE_SALE_SUCCESS';
export const DECLINE_SALE_ERROR = 'app/TransactionPage/DECLINE_SALE_ERROR';

export const CANCEL_SALE_REQUEST = 'app/TransactionPage/CANCEL_SALE_REQUEST';
export const CANCEL_SALE_SUCCESS = 'app/TransactionPage/CANCEL_SALE_SUCCESS';
export const CANCEL_SALE_ERROR = 'app/TransactionPage/CANCEL_SALE_ERROR';

export const PICK_UP_SALE_REQUEST = 'app/TransactionPage/PICK_UP_SALE_REQUEST';
export const PICK_UP_SALE_SUCCESS = 'app/TransactionPage/PICK_UP_SALE_SUCCESS';
export const PICK_UP_SALE_ERROR = 'app/TransactionPage/PICK_UP_SALE_ERROR';

export const COMPLETE_NOT_PICKEDUP_REQUEST = 'app/TransactionPage/COMPLETE_NOT_PICKEDUP_REQUEST';
export const COMPLETE_NOT_PICKEDUP_SUCCESS = 'app/TransactionPage/COMPLETE_NOT_PICKEDUP_SUCCESS';
export const COMPLETE_NOT_PICKEDUP_ERROR = 'app/TransactionPage/COMPLETE_NOT_PICKEDUP_ERROR';

export const COMPLETE_SALE_REQUEST = 'app/TransactionPage/COMPLETE_SALE_REQUEST';
export const COMPLETE_SALE_SUCCESS = 'app/TransactionPage/COMPLETE_SALE_SUCCESS';
export const COMPLETE_SALE_ERROR = 'app/TransactionPage/COMPLETE_SALE_ERROR';

export const DEFINE_SUSPICIOUS_REQUEST = 'app/TransactionPage/DEFINE_SUSPICIOUS_REQUEST';
export const DEFINE_SUSPICIOUS_SUCCESS = 'app/TransactionPage/DEFINE_SUSPICIOUS_SUCCESS';
export const DEFINE_SUSPICIOUS_ERROR = 'app/TransactionPage/DEFINE_SUSPICIOUS_ERROR';

export const CHANGE_DELIVERY_STATE_REQUEST = 'app/TransactionPage/CHANGE_DELIVERY_STATE_REQUEST';
export const CHANGE_DELIVERY_STATE_SUCCESS = 'app/TransactionPage/CHANGE_DELIVERY_STATE_SUCCESS';
export const CHANGE_DELIVERY_STATE_ERROR = 'app/TransactionPage/CHANGE_DELIVERY_STATE_ERROR';

export const FETCH_MESSAGES_REQUEST = 'app/TransactionPage/FETCH_MESSAGES_REQUEST';
export const FETCH_MESSAGES_SUCCESS = 'app/TransactionPage/FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_ERROR = 'app/TransactionPage/FETCH_MESSAGES_ERROR';

export const FETCH_REVIEW_REQUEST = 'app/TransactionPage/FETCH_REVIEW_REQUEST';
export const FETCH_REVIEW_SUCCESS = 'app/TransactionPage/FETCH_REVIEW_SUCCESS';
export const FETCH_REVIEW_ERROR = 'app/TransactionPage/FETCH_REVIEW_ERROR';

export const SEND_MESSAGE_REQUEST = 'app/TransactionPage/SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'app/TransactionPage/SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_ERROR = 'app/TransactionPage/SEND_MESSAGE_ERROR';

export const SEND_REVIEW_REQUEST = 'app/TransactionPage/SEND_REVIEW_REQUEST';
export const SEND_REVIEW_SUCCESS = 'app/TransactionPage/SEND_REVIEW_SUCCESS';
export const SEND_REVIEW_ERROR = 'app/TransactionPage/SEND_REVIEW_ERROR';

export const FETCH_TIME_SLOTS_REQUEST = 'app/TransactionPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/TransactionPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/TransactionPage/FETCH_TIME_SLOTS_ERROR';

export const PARTIAL_CANCEL_REQUEST = 'app/TransactionPage/PARTIAL_CANCEL_REQUEST';
export const PARTIAL_CANCEL_SUCCESS = 'app/TransactionPage/PARTIAL_CANCEL_SUCCESS';
export const PARTIAL_CANCEL_ERROR = 'app/TransactionPage/PARTIAL_CANCEL_ERROR';

export const FETCH_TRANSACTION_PROLONGATION_SUCCCESS = 'app/TransactionPage/FETCH_TRANSACTION_PROLONGATION_SUCCESS';

// ================ Reducer ================ //

const initialState = {
  fetchTransactionInProgress: false,
  fetchTransactionError: null,
  transactionRef: null,
  acceptInProgress: false,
  acceptSaleError: null,
  declineInProgress: false,
  declineSaleError: null,
  cancelInProgress: false,
  cancelSaleError: null,
  pickupInProgress: false,
  pickupSaleError: null,
  completeNotPickedupInProgress: false,
  completeNotPickedupError: null,
  completeInProgress: false,
  completeSaleError: null,
  fetchMessagesInProgress: false,
  fetchMessagesError: null,
  totalMessages: 0,
  totalMessagePages: 0,
  oldestMessagePageFetched: 0,
  messages: [],
  review: null,
  canBeProlongedTo: null,
  fetchReviewInProgress: false,
  fetchReviewError: null,
  initialMessageFailedToTransaction: null,
  savePaymentMethodFailed: false,
  sendMessageInProgress: false,
  sendMessageError: null,
  sendReviewInProgress: false,
  sendReviewError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  fetchTransitionsInProgress: false,
  fetchTransitionsError: null,
  processTransitions: null,
  partialCancelInProgress: false,
  partialCancelError: null,
  partialCancelSuccess: false,
  defineSuspiciousInProgress: false,
  defineSuspiciousError: null,
  changeDeliveryStateInProgress: false,
  changeDeliveryStateError: null,
};

// Merge entity arrays using ids, so that conflicting items in newer array (b) overwrite old values (a).
// const a = [{ id: { uuid: 1 } }, { id: { uuid: 3 } }];
// const b = [{ id: : { uuid: 2 } }, { id: : { uuid: 1 } }];
// mergeEntityArrays(a, b)
// => [{ id: { uuid: 3 } }, { id: : { uuid: 2 } }, { id: : { uuid: 1 } }]
const mergeEntityArrays = (a, b) => {
  return a.filter(aEntity => !b.find(bEntity => aEntity.id.uuid === bEntity.id.uuid)).concat(b);
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITAL_VALUES:
      return { ...initialState, ...payload };

    case FETCH_TRANSACTION_REQUEST:
      return { ...state, fetchTransactionInProgress: true, fetchTransactionError: null };
    case FETCH_TRANSACTION_SUCCESS: {
      const transactionRef = { id: payload.data.data.id, type: 'transaction' };
      return { ...state, fetchTransactionInProgress: false, transactionRef };
    }
    case FETCH_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchTransactionInProgress: false, fetchTransactionError: payload };

    case FETCH_TRANSITIONS_REQUEST:
      return { ...state, fetchTransitionsInProgress: true, fetchTransitionsError: null };
    case FETCH_TRANSITIONS_SUCCESS:
      return { ...state, fetchTransitionsInProgress: false, processTransitions: payload };
    case FETCH_TRANSITIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchTransitionsInProgress: false, fetchTransitionsError: payload };

    case ACCEPT_SALE_REQUEST:
      return { ...state, acceptInProgress: true, acceptSaleError: null, declineSaleError: null };
    case ACCEPT_SALE_SUCCESS:
      return { ...state, acceptInProgress: false };
    case ACCEPT_SALE_ERROR:
      return { ...state, acceptInProgress: false, acceptSaleError: payload };

    case DECLINE_SALE_REQUEST:
      return { ...state, declineInProgress: true, declineSaleError: null, acceptSaleError: null };
    case DECLINE_SALE_SUCCESS:
      return { ...state, declineInProgress: false };
    case DECLINE_SALE_ERROR:
      return { ...state, declineInProgress: false, declineSaleError: payload };

    case CANCEL_SALE_REQUEST:
      return { ...state, cancelInProgress: true, cancelSaleError: null, acceptSaleError: null };
    case CANCEL_SALE_SUCCESS:
      return { ...state, cancelInProgress: false };
    case CANCEL_SALE_ERROR:
      return { ...state, cancelInProgress: false, cancelSaleError: payload };

    case PICK_UP_SALE_REQUEST:
      return { ...state, pickupInProgress: true, pickupSaleError: null, acceptSaleError: null };
    case PICK_UP_SALE_SUCCESS:
      return { ...state, pickupInProgress: false };
    case PICK_UP_SALE_ERROR:
      return { ...state, pickupInProgress: false, pickupSaleError: payload };

    case COMPLETE_NOT_PICKEDUP_REQUEST:
      return { ...state, completeNotPickedupInProgress: true, completeNotPickedupError: null, acceptSaleError: null };
    case COMPLETE_NOT_PICKEDUP_SUCCESS:
      return { ...state, completeNotPickedupInProgress: false };
    case COMPLETE_NOT_PICKEDUP_ERROR:
      return { ...state, completeNotPickedupInProgress: false, completeNotPickedupError: payload };

    case COMPLETE_SALE_REQUEST:
      return { ...state, completeInProgress: true, completeSaleError: null, acceptSaleError: null };
    case COMPLETE_SALE_SUCCESS:
      return { ...state, completeInProgress: false };
    case COMPLETE_SALE_ERROR:
      return { ...state, completeInProgress: false, completeSaleError: payload };


    case DEFINE_SUSPICIOUS_REQUEST:
      return { ...state, defineSuspiciousInProgress: true, defineSuspiciousError: null, defineSuspiciousError: null };
    case DEFINE_SUSPICIOUS_SUCCESS:
      return { ...state, defineSuspiciousnProgress: false };
    case DEFINE_SUSPICIOUS_ERROR:
      return { ...state, defineSuspiciousInProgress: false, defineSuspiciousError: payload };

    case CHANGE_DELIVERY_STATE_REQUEST:
      return { ...state, changeDeliveryStateInProgress: true, changeDeliveryStateError: null, changeDeliveryStateError: null };
    case CHANGE_DELIVERY_STATE_SUCCESS:
      return { ...state, changeDeliveryStateInProgress: false };
    case CHANGE_DELIVERY_STATE_ERROR:
      return { ...state, changeDeliveryStateInProgress: false, changeDeliveryStateError: payload };

    case FETCH_MESSAGES_REQUEST:
      return { ...state, fetchMessagesInProgress: true, fetchMessagesError: null };
    case FETCH_MESSAGES_SUCCESS: {
      const oldestMessagePageFetched =
        state.oldestMessagePageFetched > payload.page
          ? state.oldestMessagePageFetched
          : payload.page;
      return {
        ...state,
        fetchMessagesInProgress: false,
        messages: mergeEntityArrays(state.messages, payload.messages),
        totalMessages: payload.totalItems,
        totalMessagePages: payload.totalPages,
        oldestMessagePageFetched,
      };
    }

    case FETCH_MESSAGES_ERROR:
      return { ...state, fetchMessagesInProgress: false, fetchMessagesError: payload };

    case FETCH_REVIEW_ERROR:
      return { ...state, fetchReviewInProgress: false, fetchReviewError: payload };

    case FETCH_REVIEW_REQUEST:
      return { ...state, fetchReviewInProgress: true, fetchReviewError: null };
    case FETCH_REVIEW_SUCCESS: {
      return {
        ...state,
        fetchReviewInProgress: false,
        review: payload.review,
      };
    }

    case SEND_MESSAGE_REQUEST:
      return {
        ...state,
        sendMessageInProgress: true,
        sendMessageError: null,
        initialMessageFailedToTransaction: null,
      };
    case SEND_MESSAGE_SUCCESS:
      return { ...state, sendMessageInProgress: false };
    case SEND_MESSAGE_ERROR:
      return { ...state, sendMessageInProgress: false, sendMessageError: payload };

    case SEND_REVIEW_REQUEST:
      return { ...state, sendReviewInProgress: true, sendReviewError: null };
    case SEND_REVIEW_SUCCESS:
      return { ...state, sendReviewInProgress: false, review: payload.review };
    case SEND_REVIEW_ERROR:
      return { ...state, sendReviewInProgress: false, sendReviewError: payload };

    case FETCH_TIME_SLOTS_REQUEST:
      return { ...state, fetchTimeSlotsError: null };
    case FETCH_TIME_SLOTS_SUCCESS:
      return { ...state, timeSlots: payload };
    case FETCH_TIME_SLOTS_ERROR:
      return { ...state, fetchTimeSlotsError: payload };

    case PARTIAL_CANCEL_REQUEST:
      return { ...state, partialCancelInProgress: true, partialCancelError: null, partialCancelSuccess: false };
    case PARTIAL_CANCEL_SUCCESS:
      return { ...state, partialCancelInProgress: false, partialCancelSuccess: true };
    case PARTIAL_CANCEL_ERROR:
      return { ...state, partialCancelInProgress: false, partialCancelError: payload, partialCancelSuccess: false };

    case FETCH_TRANSACTION_PROLONGATION_SUCCCESS:
      return { ...state, canBeProlongedTo: payload.data.data.attributes.canBeProlongedToDates };

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const acceptOrDeclineInProgress = state => {
  return state.TransactionPage.acceptInProgress || state.TransactionPage.declineInProgress || state.TransactionPage.pickupInProgress || state.TransactionPage.completeInProgress;
};

// ================ Action creators ================ //
export const setInitialValues = initialValues => ({
  type: SET_INITAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const fetchTransactionRequest = () => ({ type: FETCH_TRANSACTION_REQUEST });
const fetchTransactionSuccess = response => ({
  type: FETCH_TRANSACTION_SUCCESS,
  payload: response,
});
const fetchTransactionError = e => ({ type: FETCH_TRANSACTION_ERROR, error: true, payload: e });

const acceptSaleRequest = () => ({ type: ACCEPT_SALE_REQUEST });
const acceptSaleSuccess = () => ({ type: ACCEPT_SALE_SUCCESS });
const acceptSaleError = e => ({ type: ACCEPT_SALE_ERROR, error: true, payload: e });

const declineSaleRequest = () => ({ type: DECLINE_SALE_REQUEST });
const declineSaleSuccess = () => ({ type: DECLINE_SALE_SUCCESS });
const declineSaleError = e => ({ type: DECLINE_SALE_ERROR, error: true, payload: e });

const cancelSaleRequest = () => ({ type: CANCEL_SALE_REQUEST });
const cancelSaleSuccess = () => ({ type: CANCEL_SALE_SUCCESS });
const cancelSaleError = e => ({ type: CANCEL_SALE_ERROR, error: true, payload: e });

const pickupSaleRequest = () => ({ type: PICK_UP_SALE_REQUEST });
const pickupSaleSuccess = () => ({ type: PICK_UP_SALE_SUCCESS });
const pickupSaleError = e => ({ type: PICK_UP_SALE_ERROR, error: true, payload: e });

const completeNotPickedupRequest = () => ({ type: COMPLETE_NOT_PICKEDUP_REQUEST });
const completeNotPickedupSuccess = () => ({ type: COMPLETE_NOT_PICKEDUP_SUCCESS });
const completeNotPickedupError = e => ({ type: COMPLETE_NOT_PICKEDUP_ERROR, error: true, payload: e });

const completeSaleRequest = () => ({ type: COMPLETE_SALE_REQUEST });
const completeSaleSuccess = () => ({ type: COMPLETE_SALE_SUCCESS });
const completeSaleError = e => ({ type: COMPLETE_SALE_ERROR, error: true, payload: e });

const defineSuspiciousRequest = () => ({ type: DEFINE_SUSPICIOUS_REQUEST });
const defineSuspiciousSuccess = () => ({ type: DEFINE_SUSPICIOUS_SUCCESS });
const defineSuspiciousError = e => ({ type: DEFINE_SUSPICIOUS_ERROR, error: true, payload: e });

const changeDeliveryStateRequest = () => ({ type: CHANGE_DELIVERY_STATE_REQUEST });
const changeDeliveryStateSuccess = () => ({ type: CHANGE_DELIVERY_STATE_SUCCESS });
const changeDeliveryStateError = e => ({ type: CHANGE_DELIVERY_STATE_ERROR, error: true, payload: e });

const fetchMessagesRequest = () => ({ type: FETCH_MESSAGES_REQUEST });
const fetchMessagesSuccess = (messages, pagination) => ({
  type: FETCH_MESSAGES_SUCCESS,
  payload: { messages, ...pagination },
});
const fetchMessagesError = e => ({ type: FETCH_MESSAGES_ERROR, error: true, payload: e });

const fetchReviewRequest = () => ({ type: FETCH_REVIEW_REQUEST });
const fetchReviewSuccess = (review) => ({
  type: FETCH_REVIEW_SUCCESS,
  payload: { review },
});
const fetchReviewError = e => ({ type: FETCH_REVIEW_ERROR, error: true, payload: e });

const sendMessageRequest = () => ({ type: SEND_MESSAGE_REQUEST });
const sendMessageSuccess = () => ({ type: SEND_MESSAGE_SUCCESS });
const sendMessageError = e => ({ type: SEND_MESSAGE_ERROR, error: true, payload: e });

const sendReviewRequest = () => ({ type: SEND_REVIEW_REQUEST });
const sendReviewSuccess = (review) => ({ type: SEND_REVIEW_SUCCESS, payload: { review } });
const sendReviewError = e => ({ type: SEND_REVIEW_ERROR, error: true, payload: e });

const partialCancelRequest = () => ({ type: PARTIAL_CANCEL_REQUEST });
const partialCancelSuccess = () => ({ type: PARTIAL_CANCEL_SUCCESS });
const partialCancelError = e => ({ type: PARTIAL_CANCEL_ERROR, error: true, payload: e });

const fetchTransactionProlongationSuccess = response => ({
  type: FETCH_TRANSACTION_PROLONGATION_SUCCCESS,
  payload: response,
});

// ================ Thunks ================ //

export const fetchTransaction = (id, txRole) => (dispatch, getState, sdk) => {
  dispatch(fetchTransactionRequest());

  return sdk.newSdk.transactions
    .show(
      {
        id: id.uuid,
        include: [
          'user',
          'user.image',
          'provider',
          'provider.image',
          'listings',
          'deliveryToProvider',
          'listings.images',
          'prolongGroup'
        ],
      },
      { expand: true }
    )
    // .then(response => {
    //   txResponse = response;
    //   const entities = updatedEntities({}, response.data);
    //   const listings = response.data.data.relationships.listings;
    //   const dateRx = Promise.all(listings.data.map(({ id: listingId }) => {
    //     const listingRef = { id: listingId, type: 'listing' };
    //     const transactionRef = { id, type: 'transaction' };
    //     const denormalised = denormalisedEntities(entities, [listingRef, transactionRef]);
    //     const listing = denormalised[0];

    //     const canFetchListing = listing && listing.attributes && !listing.attributes.deleted;
    //     if (!canFetchListing) {
    //       console.log('canFetchListings')
    //       return sdk.newSdk.listings.show({
    //         id: listingId.uuid,
    //         include: ['provider', 'provider.image', 'images'],
    //       });
    //     } else {
    //       return response;
    //     }
    //   }))
    //   return dateRx;
    // })
    .then(response => {
      dispatch(addMarketplaceEntities({
        data: {
          data: {
            ...response.data.data,
            relationships: {
              ...response.data.data.relationships,
              prolongGroup: {
                data: response.data.data.relationships.prolongGroup.data.map(p => ({...p, type: 'prolong-group-transaction'})).filter(i => i.id.uuid !== id.uuid)
              }
            }
          },
          included: response.data.included.map(i => {
            if (i.type === 'transaction') {
              return {
                ...i,
                type: 'prolong-group-transaction'
              }
            } else {
              return i
            }
          }).filter(i => i.id.uuid !== id.uuid)
        },
      }));
      // responses.map(response => dispatch(addMarketplaceEntities(response)));
      dispatch(fetchTransactionSuccess(response));

      const { data } = response.data;

      if (txRole === 'customer' && (data.attributes.processState === 'accepted' || data.attributes.processState === 'picked-up')) {
        dispatch(fetchTransactionProlongation(id))
          .then(res => {
            dispatch(fetchTransactionProlongationSuccess(res));
          });
      }
      return response;
    })
    .catch(e => {
      dispatch(fetchTransactionError(storableError(e)));
      throw e;
    });
};

export const fetchTransactionProlongation = (id) => (dispatch, getState, sdk) => {
  dispatch(fetchTransactionRequest());

  return sdk.newSdk.transactions
    .show(
      {
        id: id.uuid,
        'fields.transaction': ['canBeProlongedToDates'],
      },
    )
    .then(response => {
      return response;
    })
    // .then(responses => {
    //   dispatch(addMarketplaceEntities(response));
    //   dispatch(fetchTransactionSuccess(response));
    //   return responses;
    // })
    .catch(e => {
      dispatch(fetchTransactionError(storableError(e)));
      throw e;
    });
};

export const acceptSale = id => (dispatch, getState, sdk) => {
  if (acceptOrDeclineInProgress(getState())) {
    return Promise.reject(new Error('Accept or decline already in progress'));
  }
  dispatch(acceptSaleRequest());

  return sdk.transactions
    .transition({ id, transition: TRANSITION_ACCEPT, params: {} }, { expand: true })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(acceptSaleSuccess());
      // dispatch(fetchCurrentUserNotifications());
      return response;
    })
    .catch(e => {
      dispatch(acceptSaleError(storableError(e)));
      log.error(e, 'accept-sale-failed', {
        txId: id,
        transition: TRANSITION_ACCEPT,
      });
      throw e;
    });
};

export const declineSale = id => (dispatch, getState, sdk) => {
  if (acceptOrDeclineInProgress(getState())) {
    return Promise.reject(new Error('Accept or decline already in progress'));
  }
  dispatch(declineSaleRequest());

  return sdk.transactions
    .transition({ id, transition: TRANSITION_DECLINE, params: {} }, { expand: true })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(declineSaleSuccess());
      // dispatch(fetchCurrentUserNotifications());
      return response;
    })
    .catch(e => {
      dispatch(declineSaleError(storableError(e)));
      log.error(e, 'reject-sale-failed', {
        txId: id,
        transition: TRANSITION_DECLINE,
      });
      throw e;
    });
};

export const cancelSale = id => (dispatch, getState, sdk) => {
  if (acceptOrDeclineInProgress(getState())) {
    return Promise.reject(new Error('Accept or decline already in progress'));
  }
  dispatch(cancelSaleRequest());

  return sdk.newSdk.transactions
    .cancel({ id: id.uuid })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(cancelSaleSuccess());
      return response;
    })
    .catch(e => {
      dispatch(cancelSaleError(storableError(e)));
      log.error(e, 'reject-sale-failed', {
        txId: id,
        transition: TRANSITION_CANCEL_BY_PROVIDER,
      });
      throw e;
    });
};

export const pickupSale = id => (dispatch, getState, sdk) => {
  if (acceptOrDeclineInProgress(getState())) {
    return Promise.reject(new Error('Accept or decline already in progress'));
  }
  dispatch(pickupSaleRequest());

  return sdk.newSdk.transactions
    .pickup({ id: id.uuid })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(pickupSaleSuccess());
      // dispatch(fetchCurrentUserNotifications());
      return response;
    })
    .catch(e => {
      dispatch(pickupSaleError(storableError(e)));
      log.error(e, 'accept-sale-failed', {
        txId: id,
        transition: TRANSITION_PICK_UP,
      });
      throw e;
    });
};

export const completeNotPickedupSale = id => (dispatch, getState, sdk) => {
  if (acceptOrDeclineInProgress(getState())) {
    return Promise.reject(new Error('Accept or decline already in progress'));
  }
  dispatch(completeNotPickedupRequest());

  return sdk.newSdk.transactions
    .completeWithoutPickup({ id: id.uuid })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(completeNotPickedupSuccess());
      // dispatch(fetchCurrentUserNotifications());
      return response;
    })
    .catch(e => {
      dispatch(completeNotPickedupError(storableError(e)));
      log.error(e, 'complete-not-pickedup-failed', {
        txId: id,
        transition: TRANSITION_COMPLETE_NOT_PICKEDUP,
      });
      throw e;
    });
};

export const updateTransaction = id => (dispatch, getState, sdk) => {
  dispatch(completeSaleRequest());

  return sdk.newSdk.transactions
    .update({ id: id.uuid, notRespondedMessage: false })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      return response;
    })
    .catch(e => {
      dispatch(completeSaleError(storableError(e)));
      log.error(e, 'accept-sale-failed', {
        txId: id,
        transition: TRANSITION_COMPLETE,
      });
      throw e;
    });
};

export const completeSale = id => (dispatch, getState, sdk) => {
  if (acceptOrDeclineInProgress(getState())) {
    return Promise.reject(new Error('Accept or decline already in progress'));
  }
  dispatch(completeSaleRequest());

  return sdk.newSdk.transactions
    .deliver({ id: id.uuid })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(completeSaleSuccess());
      // dispatch(fetchCurrentUserNotifications());
      return response;
    })
    .catch(e => {
      dispatch(completeSaleError(storableError(e)));
      log.error(e, 'accept-sale-failed', {
        txId: id,
        transition: TRANSITION_COMPLETE,
      });
      throw e;
    });
};

export const defineSuspicious = (id, data) => (dispatch, getState, sdk) => {
  dispatch(defineSuspiciousRequest());

  const { suspicious, reason } = data;

  return sdk.newSdk.transactions
    .update({ id: id.uuid, suspicious, reason })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(defineSuspiciousSuccess());
      return response;
    })
    .catch(e => {
      dispatch(defineSuspiciousError(storableError(e)));
      log.error(e, 'accept-sale-failed', {
        txId: id,
        transition: TRANSITION_COMPLETE,
      });
      throw e;
    });
};

export const changeDeliveryState = (id, deliveryState) => (dispatch, getState, sdk) => {
  dispatch(changeDeliveryStateRequest());

  return sdk.newSdk.transactions
    .update({ id: id.uuid, deliveryState })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(changeDeliveryStateSuccess());
      return response;
    })
    .catch(e => {
      dispatch(changeDeliveryStateError(storableError(e)));
      log.error(e, 'accept-sale-failed', {
        txId: id,
        transition: TRANSITION_COMPLETE,
      });
      throw e;
    });
};

const fetchMessages = (txId, page) => (dispatch, getState, sdk) => {
  const paging = { page, per_page: MESSAGES_PAGE_SIZE };
  dispatch(fetchMessagesRequest());

  return sdk.newSdk.messages
    .query({
      context: 'transaction', transactionId: txId.uuid,
      include: ['sender'],
      ...paging,
    })
    .then(response => {
      const messages = denormalisedResponseEntities(response);
      const { totalItems, totalPages, page: fetchedPage } = response.data.meta;
      const pagination = { totalItems, totalPages, page: fetchedPage };
      const totalMessages = getState().TransactionPage.totalMessages;

      // Original fetchMessages call succeeded
      dispatch(fetchMessagesSuccess(messages, pagination));

      // Check if totalItems has changed between fetched pagination pages
      // if totalItems has changed, fetch first page again to include new incoming messages.
      // TODO if there're more than 100 incoming messages,
      // this should loop through most recent pages instead of fetching just the first one.
      if (totalItems > totalMessages && page > 1) {
        dispatch(fetchMessages(txId, 1))
          .then(() => {
            // Original fetch was enough as a response for user action,
            // this just includes new incoming messages
          })
          .catch(() => {
            // Background update, no need to to do anything atm.
          });
      }
    })
    .catch(e => {
      dispatch(fetchMessagesError(storableError(e)));
      throw e;
    });
};

export const fetchMoreMessages = txId => (dispatch, getState, sdk) => {
  const state = getState();
  const { oldestMessagePageFetched, totalMessagePages } = state.TransactionPage;
  const hasMoreOldMessages = totalMessagePages > oldestMessagePageFetched;

  // In case there're no more old pages left we default to fetching the current cursor position
  const nextPage = hasMoreOldMessages ? oldestMessagePageFetched + 1 : oldestMessagePageFetched;

  return dispatch(fetchMessages(txId, nextPage));
};

export const sendMessage = (txId, message) => (dispatch, getState, sdk) => {
  dispatch(sendMessageRequest());

  return sdk.newSdk.messages
    .create({ transactionId: txId.uuid, text: message, context: 'transaction' })
    .then(response => {
      const messageId = response.data.data.id;

      // We fetch the first page again to add sent message to the page data
      // and update possible incoming messages too.
      // TODO if there're more than 100 incoming messages,
      // this should loop through most recent pages instead of fetching just the first one.
      return dispatch(fetchMessages(txId, 1))
        .then(() => {
          dispatch(sendMessageSuccess());
          return messageId;
        })
        .catch(() => dispatch(sendMessageSuccess()));
    })
    .catch(e => {
      dispatch(sendMessageError(storableError(e)));
      // Rethrow so the page can track whether the sending failed, and
      // keep the message in the form for a retry.
      throw e;
    });
};

const REVIEW_TX_INCLUDES = ['reviews', 'reviews.author', 'reviews.subject'];
const IMAGE_VARIANTS = {
  'fields.image': [
    // Profile images
    'variants.square-small',
    'variants.square-small2x',

    // Listing images:
    'variants.landscape-crop',
    'variants.landscape-crop2x',
  ],
};

// If other party has already sent a review, we need to make transition to
// TRANSITION_REVIEW_2_BY_<CUSTOMER/PROVIDER>
const sendReviewAsSecond = (id, params, role, dispatch, sdk) => {
  const transition = getReview2Transition(role === CUSTOMER);

  const include = REVIEW_TX_INCLUDES;

  return sdk.transactions
    .transition({ id, transition, params }, { expand: true, include, ...IMAGE_VARIANTS })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(sendReviewSuccess());
      return response;
    })
    .catch(e => {
      dispatch(sendReviewError(storableError(e)));

      // Rethrow so the page can track whether the sending failed, and
      // keep the message in the form for a retry.
      throw e;
    });
};

// If other party has not yet sent a review, we need to make transition to
// TRANSITION_REVIEW_1_BY_<CUSTOMER/PROVIDER>
// However, the other party might have made the review after previous data synch point.
// So, error is likely to happen and then we must try another state transition
// by calling sendReviewAsSecond().
const sendReviewAsFirst = (id, params, role, dispatch, sdk) => {
  const include = REVIEW_TX_INCLUDES;

  return sdk.newSdk.reviews
    .create({ transactionId: id.uuid, rating: params.reviewRating, text: params.reviewContent }, { expand: true, include, ...IMAGE_VARIANTS })
    .then(response => {
      const review = denormalisedResponseEntities(response);
      dispatch(sendReviewSuccess(review[0]));
      return response;
    })
    .catch(e => {
      // If transaction transition is invalid, lets try another endpoint.
      if (isTransactionsTransitionInvalidTransition(e)) {
        return sendReviewAsSecond(id, params, role, dispatch, sdk);
      } else {
        dispatch(sendReviewError(storableError(e)));

        // Rethrow so the page can track whether the sending failed, and
        // keep the message in the form for a retry.
        throw e;
      }
    });
};

export const sendReview = (role, tx, reviewRating, reviewContent) => (dispatch, getState, sdk) => {
  const params = { reviewRating, reviewContent };

  dispatch(sendReviewRequest());

  return sendReviewAsFirst(tx.id, params, role, dispatch, sdk);
};

const isNonEmpty = value => {
  return typeof value === 'object' || Array.isArray(value) ? !isEmpty(value) : !!value;
};

export const fetchTransactionOnly = (id, txRole, withListingAndBooking = false) => (dispatch, getState, sdk) => {
  dispatch(fetchTransactionRequest());
  let txResponse = null;
  const includeListingAndBooking = withListingAndBooking ? {
    include: 'listings'
  } : {};

  return sdk.newSdk.transactions
    .show(
      {
        id: id.uuid,
        ...includeListingAndBooking
      },
      { expand: true }
    )
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchTransactionSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchTransactionError(storableError(e)));
      throw e;
    });
};

export const fetchReview = (txId) => (dispatch, getState, sdk) => {
  dispatch(fetchReviewRequest());

  return sdk.newSdk.reviews
    .query(
      {
        transactionId: txId.uuid
      },
      { expand: true }
    )
    .then(response => {
      const review = denormalisedResponseEntities(response);
      dispatch(fetchReviewSuccess(review[0]));
      return response;
    })
    .catch(e => {
      dispatch(fetchReviewError(storableError(e)));
      throw e;
    });
};

// loadData is a collection of async calls that need to be made
// before page has all the info it needs to render itself
export const loadData = params => (dispatch, getState) => {
  const txId = new UUID(params.id);
  const state = getState().TransactionPage;
  const txRef = state.transactionRef;
  const txRole = params.transactionRole;

  // In case a transaction reference is found from a previous
  // data load -> clear the state. Otherwise keep the non-null
  // and non-empty values which may have been set from a previous page.
  const initialValues = txRef ? {} : pickBy(state, isNonEmpty);
  dispatch(setInitialValues(initialValues));

  // Sale / order (i.e. transaction entity in API)
  return Promise.all([
    dispatch(fetchTransaction(txId, txRole)),
    dispatch(fetchReview(txId)),
    dispatch(fetchMessages(txId, 1))
  ])
};

export const partialCancel = (transactionId, items) => (dispatch, getState, sdk) => {
  if (acceptOrDeclineInProgress(getState())) {
    return Promise.reject(new Error('Accept or decline already in progress'));
  }
  dispatch(partialCancelRequest());

  return sdk.newSdk.transactions
    .partialCancel({ id: transactionId, items })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(partialCancelSuccess());
      return response;
    })
    .catch(e => {
      dispatch(partialCancelError(storableError(e)));
      log.error(e, 'reject-partial-cancel-failed', {
        txId: transactionId,
        transition: 'partial-cancel',
      });
      throw e;
    });
};
