import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { arrayOf, bool, shape, string, func } from 'prop-types';

import { isScrollingDisabled } from '../../ducks/UI.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { loadData, requestUpdateUser } from './ConsoleUserPage.duck';
import { PaginationLinks, ConsoleWrapper, IconSpinner, Input } from '../../components';
import UserItem from './UserItem/UserItem';
import { parse, stringify } from '../../util/urlHelpers';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';

import css from './ConsoleUserPage.css';

const ConsoleUserPageComponent = props => {
  const {
    users,
    fetchInProgress,
    scrollingDisabled,
    fetchUserListError,
    pagination,
    intl,
    location: { search, pathname },
    history,
    tab,
    name,
    updateUser,
    updateUserInProgress,
  } = props;
  const { phrase, page } = parse(search);
  const [searchPhrase, setSearchPhrase] = useState(phrase);

  const pagingLinks =
    !fetchInProgress && pagination && pagination?.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName={name}
        pagePathParams={{ tab }}
        pageSearchParams={parse(search)}
        pagination={pagination}
      />
    ) : null;

  const errorMessage = fetchUserListError ? (
    <p className={css.error}>{fetchUserListError?.message}</p>
  ) : null;

  const noResultsMessage =
    !fetchInProgress && !users?.length && !fetchUserListError ? (
      <p>
        <FormattedMessage id="Console.noUsersFound" />
      </p>
    ) : null;

  const handleChange = event => {
    setSearchPhrase(event.target.value);
  };

  const handleSubmit = event => {
    event.preventDefault();
    history.push({
      pathname,
      search: `${stringify({
        ...parse(search),
        phrase: searchPhrase || undefined,
        page: 1,
      })}`,
    });
  };

  const userSearch = (
    <form onSubmit={handleSubmit} className={css.searchInputWrapper}>
      <Input
        value={searchPhrase}
        onChange={handleChange}
        placeholder={intl.formatMessage({ id: 'Console.searchUser' })}
        label={intl.formatMessage({ id: 'Console.search' })}
      />
    </form>
  );

  useEffect(() => {
    if (!page) {
      setSearchPhrase('');
      history.push({ pathname, search: `${stringify({ ...parse(search), page: 1 })}` });
    }
  }, [page]);

  return (
    <ConsoleWrapper scrollingDisabled={scrollingDisabled} name={name} tab={tab}>
      {errorMessage}
      {userSearch}
      {fetchInProgress ? (
        <div className={css.listItemsLoading}>
          <IconSpinner />
        </div>
      ) : (
        <ul>
          {users?.map(user => {
            const {
              id: { uuid },
              attributes: { firstName, lastName, language, email, createdAt, banned },
            } = user;
            const date = intl.formatDate(createdAt, {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            });

            return (
              <UserItem
                key={uuid}
                userId={uuid}
                firstName={firstName}
                lastName={lastName}
                language={language}
                email={email}
                createdAt={date}
                updateUser={updateUser}
                updateUserInProgress={updateUserInProgress}
                banned={banned}
              />
            );
          })}
        </ul>
      )}
      {noResultsMessage}
      {pagingLinks}
    </ConsoleWrapper>
  );
};

ConsoleUserPageComponent.defaultProps = {
  users: [],
  fetchInProgress: false,
  fetchUserListError: null,
  pagination: null,
  scrollingDisabled: false,
  name: null,
  tab: null,
};

ConsoleUserPageComponent.propTypes = {
  fetchInProgress: bool.isRequired,
  fetchUserListError: propTypes.error,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  users: arrayOf(propTypes.userDetails),
  intl: intlShape.isRequired,
  name: string.isRequired,
  tab: string.isRequired,
  history: shape({ push: func.isRequired }).isRequired,
  location: shape({ search: string, pathname: string }).isRequired,
  updateUser: func.isRequired,
  updateUserInProgress: bool.isRequired,
};

const mapStateToProps = state => {
  const {
    fetchInProgress,
    fetchUserListError,
    pagination,
    userList,
    updateUserInProgress,
  } = state.ConsoleUserPage;
  return {
    fetchInProgress,
    fetchUserListError,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    users: getMarketplaceEntities(state, userList),
    updateUserInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  updateUser: data => dispatch(requestUpdateUser(data)),
});

const ConsoleUserPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withRouter
)(ConsoleUserPageComponent);

ConsoleUserPage.loadData = loadData;

export default ConsoleUserPage;
