import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { string, node, bool } from 'prop-types';

import {
  Page,
  TabNav,
  LayoutSideNavigation,
  Footer,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
} from '..';
import { NotFoundPage, TopbarContainer } from '../../containers';

import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import config from '../../config';

import css from './ConsoleWrapper.css';
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import { countryId as defaultCountryId } from '../../marketplace-custom-config';

const tabData = [
  {
    tabName: 'transactions',
    pageName: 'ConsoleTransactionPage',
    pageTitle: 'Console.transactionsTitle',
    tabTitle: 'Console.transactionsTabTitle',
    permissionForCountryAdmin: true,
  },
  {
    tabName: 'users',
    pageName: 'ConsoleUserPage',
    pageTitle: 'Console.usersTitle',
    tabTitle: 'Console.usersTabTitle',
    permissionForCountryAdmin: false,
  },
  {
    tabName: 'providers',
    pageName: 'ConsoleProviderPage',
    pageTitle: 'Console.providersTitle',
    tabTitle: 'Console.providersTabTitle',
    permissionForCountryAdmin: true,
  },
  {
    tabName: 'reviews',
    pageName: 'ConsoleReviewPage',
    pageTitle: 'Console.reviewsTitle',
    tabTitle: 'Console.reviewsTabTitle',
    permissionForCountryAdmin: true,
  },
  {
    tabName: 'coupons',
    pageName: 'ConsoleCouponsPage',
    pageTitle: 'Console.couponsTitle',
    tabTitle: 'Console.couponsTabTitle',
    permissionForCountryAdmin: false,
  },
  {
    tabName: 'productCatalog',
    pageName: 'ConsoleProductCatalogPage',
    pageTitle: 'Console.productCatalogTitle',
    tabTitle: 'Console.productCatalogTabTitle',
    permissionForCountryAdmin: true,
  },
];

const includeCountryId = ['reviews', 'transactions'];

const ConsoleWrapperComponent = ({ children, intl, scrollingDisabled, name, tab, currentUser }) => {
  if (!tabData.some(({ tabName }) => tabName === tab)) return <NotFoundPage />;
  const isAdmin = currentUser?.attributes.isAdmin;
  const rowData = tabData.find(({ pageName }) => pageName === name);
  const { pageTitle } = rowData;
  const title = intl.formatMessage({ id: pageTitle });
  const adminCountries = currentUser && currentUser.attributes.adminForCountries;
  const defaultAdminCountryId = (isAdmin || (adminCountries && adminCountries.includes(defaultCountryId)))
    ? defaultCountryId
    : adminCountries ? adminCountries[0] : null;

  const tabsWithoutProductCatalog = tabData.filter(item => item.tabName !== 'productCatalog');

  const tabsToShow = isAdmin
    ? tabsWithoutProductCatalog
    : tabsWithoutProductCatalog.filter(({ permissionForCountryAdmin }) => permissionForCountryAdmin);

  const tabs = tabsToShow
    .map(({ tabName, tabTitle, pageName }) => ({
      text: (
        <span>
          <FormattedMessage id={tabTitle} />
        </span>
      ),
      selected: pageName === name,
      linkProps: {
        name: pageName,
        params: { tab: tabName },
        to: {
          search: (tabName === 'transactions' || tabName === 'coupons')
            ? `?countryId=${defaultAdminCountryId}&page=1`
            : `?page=1`
        },
      }
    }));

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage={name} />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav className={css.navigation}>
          <h1 className={css.title}>
            <FormattedMessage id="Console.title" />
          </h1>
          <TabNav rootClassName={css.tabs} tabRootClassName={css.tab} tabs={tabs} />
        </LayoutWrapperSideNav>
        <LayoutWrapperMain>{children}</LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
      <ScrollToTop />
    </Page>
  );
};

ConsoleWrapperComponent.defaultProps = {
  tab: null,
  name: null,
  children: null,
  intl: null,
  scrollingDisabled: false,
};

ConsoleWrapperComponent.propTypes = {
  tab: string.isRequired,
  name: string.isRequired,
  intl: intlShape.isRequired,
  children: node.isRequired,
  scrollingDisabled: bool.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    currentUser,
  };
};

const ConsoleWrapper = compose(
  connect(mapStateToProps),
  injectIntl,
  withRouter
)(ConsoleWrapperComponent);

export default ConsoleWrapper;
