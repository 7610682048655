import React from 'react';
import moment from 'moment';
import classNames from 'classnames';

import { NamedLink } from '../../index';

import css from './ProlongGroupSection.css';

const ProlongGroupSection = ({ transaction, intl, transactionStatus, history }) => {
  const isOrderPage = history && history.location.pathname.includes('order');
  const redirection = isOrderPage ? "OrderDetailsPage" : "SaleDetailsPage";

  return (
    <div className={css.container}>
      <h3 className={css.title}>
        {intl.formatMessage({ id: 'ProlongGroupSection.title' })}
      </h3>
      <div>
        {
          transaction.prolongGroup.map(t => {
            const transactionStatusAttributes = transactionStatus.find(
              ({ status }) => status === t.attributes.processState
            );

            return (
              <div className={css.text}>
                <NamedLink
                  name={redirection}
                  params={{ id: t.id.uuid }}
                  className={css.link}
                >
                  {t.attributes.code}
                </NamedLink>
                <span className={css.rentalDates}>
                  {moment(t.attributes.start).format("DD MMM")}{' '}
                  - {moment(t.attributes.end).subtract(1,'days').format("DD MMM")
                }</span>
                <span className={classNames(transactionStatusAttributes.class, css.status)}>
                  {intl.formatMessage({ id: transactionStatusAttributes.textStatus })}{' '}
                </span>
                <span className={css.createdDate}>
                  {moment(t.attributes.createdAt).format("DD MMM YYYY hh:mm")}
                </span>
              </div>
            )
          })
        }
      </div>
    </div>
  )
};

export default ProlongGroupSection;
