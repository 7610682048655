import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import giftImg from './gift.svg';
import giftCardImg from './giftcard.svg';
import logoImg from './logo_active.png';
import logoHuImg from './logo_deca_hu.png';
import saleImg from './sale_01.svg';

import css from './SectionActiveProgram.css';
import config from '../../config';

const SectionActiveProgram = () => {
  const link = config.locale === 'pl' ? 'https://www.decathlon.pl/program-lojalnosciowy_lp-0BCFGW' : 'https://membership.decathlon.com/hu-HU/';

  const showJoinBtn = displayClassName => (
    <a
      href={link}
      className={classNames(css.link, displayClassName)}
      target="_blank"
    >
      <FormattedMessage id={'SectionActiveProgram.button'} />
    </a>
  );

  const logo = config.locale === 'pl' ? logoImg : logoHuImg;

  return (
    <div className={css.wrapper}>
      <div className={css.container}>
        <div className={css.actionContainer}>
          <div className={css.programContainer}>
            <img src={logo} />
            <p className={css.header}>
              <FormattedMessage id={'SectionActiveProgram.heading'} />
            </p>
            <p className={css.subheader}>
              <FormattedMessage id={'SectionActiveProgram.subheading1'} />
              <FormattedMessage id={'SectionActiveProgram.subheading2'} />
            </p>
          </div>
          {showJoinBtn(css.desktop)}
        </div>
        <div className={css.infoContainer}>
          <div className={css.itemContainer}>
            <img src={giftCardImg} />
            <p className={css.title}>
              <FormattedMessage id={'SectionActiveProgram.title1'} />
            </p>
            <p className={css.text}>
              <FormattedMessage id={'SectionActiveProgram.subtitle1'} />
            </p>
          </div>
          <div className={css.itemContainer}>
            <img src={saleImg} />
            <p className={css.title}>
              <FormattedMessage id={'SectionActiveProgram.title2'} />
            </p>
            <p className={css.text}>
              <FormattedMessage id={'SectionActiveProgram.subtitle2'} />
            </p>
          </div>
          <div className={css.itemContainer}>
            <img src={giftImg} />
            <p className={css.title}>
              <FormattedMessage id={'SectionActiveProgram.title3'} />
            </p>
            <p className={css.text}>
              <FormattedMessage id={'SectionActiveProgram.subtitle3'} />
            </p>
          </div>
        </div>
        {showJoinBtn(css.mobile)}
      </div>
    </div>
  );
};

export default SectionActiveProgram;
