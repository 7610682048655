import React, { useState } from 'react';
import { arrayOf, bool, func, shape, string, object } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import {
  Form,
  Button,
  FieldCurrencyInput,
  FieldTextInput,
  IconInfo,
} from '../../components';
import CustomCategorySelectFieldMaybe from './CustomCategorySelectFieldMaybe';
import BusinessCategorySelectFieldMaybe from './BusinessCategorySelectFieldMaybe';
import CustomProviderField from './CustomProviderField';
import css from './EditListingDescriptionForm.css';
import { currencyConfiguration } from '../../currency-config';
import CatalogProductSelectField from './CatalogProductSelectField';
import CatalogItemSelectField from './CatalogItemSelectField';
import CatalogItemInfo from './CatalogItemInfo/CatalogItemInfo';
import AssignCatalogItemModal from './AssignCatalogItemModal/AssignCatalogItemModal';

const TITLE_MAX_LENGTH = 90;

const EditListingDescriptionFormComponent = props => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          categories,
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          displayPartsField,
          currentUserProviders,
          values,
          form,
          productCatalogList,
          isCatalogProduct,
          hasItemAttached,
          onManageDisableScrolling,
          isNewListing,
          userPublicData
        } = formRenderProps;
        const nonNegativeNumber = (message, number) => {
          const n = Number.parseInt(number)
          return n >= 0 || Number.isNaN(n) ? '' : message;
        };

        const providerValue = form.getFieldState('provider');
        const provider = currentUserProviders.find(item => item.id.uuid === providerValue?.value);
        const serviceDaysCount = provider ? provider.attributes.bookingCondition.serviceDaysCount : null;
        const x = <b>serviceDaysCount</b>
        const serviceDaysProviderMessage = provider
          ? <span> ({intl.formatMessage({ id: 'EditListingDescriptionForm.serviceDaysProviderInfo' })}
            <b>{serviceDaysCount}</b>).</span>
          :
          <span> ({intl.formatMessage({ id: 'EditListingDescriptionForm.chooseProvider' })}).</span>;
        const titleMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.title' });
        const titlePlaceholderMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.titlePlaceholder',
        });
        const titleRequiredMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.titleRequired',
        });
        const maxLengthMessage = intl.formatMessage(
          { id: 'EditListingDescriptionForm.maxLength' },
          {
            maxLength: TITLE_MAX_LENGTH,
          }
        );

        const descriptionMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.description',
        });
        const descriptionPlaceholderMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.descriptionPlaceholder',
        });
        const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
        const descriptionRequiredMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.descriptionRequired',
        });

        const priceLabel = intl.formatMessage({
          id: 'EditListingDescriptionForm.price',
        });
        const pricePlaceholderMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.pricePlaceholder',
        });
        const priceRequiredMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.priceRequired',
        });

        // fields
        const productCodeLabel = intl.formatMessage({
          id: 'EditListingDescriptionForm.productCodeLabel',
        });

        const productCodePlaceholder = intl.formatMessage({
          id: 'EditListingDescriptionForm.productCodePlaceholder',
        });

        const moreInfoLabel = intl.formatMessage({
          id: 'EditListingDescriptionForm.moreInfoLabel',
        });

        const moreInfoPlaceholder = intl.formatMessage({
          id: 'EditListingDescriptionForm.moreInfoPlaceholder',
        });

        const depositConditionsLabel = intl.formatMessage({
          id: 'EditListingDescriptionForm.depositConditionsLabel',
        });

        const depositConditionsPlaceholder = intl.formatMessage({
          id: 'EditListingDescriptionForm.depositConditionsPlaceholder',
        });

        const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
        const errorMessageUpdateListing = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
          </p>
        ) : null;

        // This error happens only on first tab (of EditListingWizard)
        const errorMessageCreateListingDraft = createListingDraftError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
          </p>
        ) : null;

        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
          </p>
        ) : null;

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;
        const providers = currentUserProviders.map(p => ({
          key: p.id.uuid,
          label: p.attributes.name,
          currency: p.attributes.currency
        }))
        const storeCurrency = (providers.find(p => p.key === values.provider) || { currency: 'USD' }).currency;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            {errorMessageCreateListingDraft}
            {errorMessageUpdateListing}
            {errorMessageShowListing}

            {!isNewListing && !isCatalogProduct && userPublicData?.isAdmin ?
              <div>
                <Button
                  onClick={() => setIsModalOpen(true)}
                  type='button'
                  className={css.modalBtn}
                >
                  <FormattedMessage id="EditListingDescriptionForm.assignCatalogProduct" />
                </Button>
                <AssignCatalogItemModal
                  id='AssignCatalogItemModal'
                  isOpen={isModalOpen}
                  onCloseModal={() => setIsModalOpen(false)}
                  onManageDisableScrolling={onManageDisableScrolling}
                  inProgress={submitInProgress}
                  form={form}
                  intl={intl}
                  productCatalogList={productCatalogList}
                  catalogProduct={values.catalogProduct}
                  handleSubmit={handleSubmit}
                />
              </div> : null
            }

            <CustomProviderField
              id="provider"
              name="provider"
              providers={providers}
              intl={intl}
              markRequired={true}
              disabled={hasItemAttached}
            />

            {isCatalogProduct ? <CatalogProductSelectField
              id="catalogProduct"
              name="catalogProduct"
              catalogProducts={productCatalogList}
              intl={intl}
              form={form}
              markRequired={true}
              disabled={hasItemAttached}
            /> : null}

            {isCatalogProduct ? <CatalogItemSelectField
              id="catalogItem"
              name="catalogItem"
              intl={intl}
              catalogProducts={productCatalogList}
              catalogProduct={values.catalogProduct}
              markRequired={true}
              form={form}
              disabled={hasItemAttached}
            /> : null}

            {isCatalogProduct && !hasItemAttached ?
              <CatalogItemInfo
                productId={values.catalogProduct}
                itemId={values.catalogItem}
                catalogProducts={productCatalogList}
                intl={intl}
                categories={categories}
              />
              : <>
                <FieldTextInput
                  id="title"
                  name="title"
                  className={css.title}
                  type="text"
                  label={titleMessage}
                  placeholder={titlePlaceholderMessage}
                  maxLength={TITLE_MAX_LENGTH}
                  validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
                  autoFocus
                  markRequired={true}
                  disabled={hasItemAttached}
                />

                <FieldTextInput
                  id="description"
                  name="description"
                  className={css.description}
                  type="textarea"
                  label={descriptionMessage}
                  placeholder={descriptionPlaceholderMessage}
                  validate={composeValidators(required(descriptionRequiredMessage))}
                  markRequired={true}
                  disabled={hasItemAttached || isCatalogProduct}
                />

                <FieldTextInput
                  id="code"
                  name="code"
                  className={css.description}
                  type="text"
                  label={productCodeLabel}
                  placeholder={productCodePlaceholder}
                  disabled={hasItemAttached || isCatalogProduct}
                />

                <FieldTextInput
                  id="externalLink"
                  name="externalLink"
                  className={css.description}
                  type="text"
                  label={moreInfoLabel}
                  placeholder={moreInfoPlaceholder}
                  disabled={hasItemAttached || isCatalogProduct}
                />

                <CustomCategorySelectFieldMaybe
                  id="category"
                  name="category"
                  categories={categories}
                  intl={intl}
                  form={form}
                  markRequired={true}
                  disabled={hasItemAttached || isCatalogProduct}
                />

                <BusinessCategorySelectFieldMaybe
                  id="businessCategory"
                  name="businessCategory"
                  intl={intl}
                  category={values.category}
                  businessCategory={values.businessCategory}
                  markRequired={true}
                  disabled={hasItemAttached || isCatalogProduct}
                />

                <FieldCurrencyInput
                  id="price"
                  name="price"
                  className={css.priceInput}
                  label={priceLabel}
                  placeholder={pricePlaceholderMessage}
                  currencyConfig={currencyConfiguration(storeCurrency)}
                  validate={required(priceRequiredMessage)}
                  markRequired={true}
                  disabled={hasItemAttached || isCatalogProduct || !values.provider}
                  value={values.price}
                />

                <FieldTextInput
                  id="minBookingDays"
                  name="minBookingDays"
                  className={css.description}
                  type="number"
                  label={intl.formatMessage({ id: 'EditListingDescriptionForm.minDays' })}
                  placeholder={intl.formatMessage({ id: 'EditListingDescriptionForm.minDaysPlaceholder' })}
                  validate={composeValidators(required(intl.formatMessage({ id: 'EditListingDescriptionForm.minDaysValidator' })))}
                  disabled={hasItemAttached || isCatalogProduct}
                />
              </>
            }
            {
              isCatalogProduct && hasItemAttached ? (
                <FieldTextInput
                  id="parts"
                  name="parts"
                  className={css.description}
                  type="textarea"
                  label={intl.formatMessage({ id: 'EditListingDescriptionForm.catalogParts', })}
                  placeholder={intl.formatMessage({ id: 'EditListingDescriptionForm.catalogPartsPlaceholder', })}
                  disabled={hasItemAttached || isCatalogProduct}
                />
              ) : null
            }

            <FieldTextInput
              id="customParts"
              name="customParts"
              className={css.description}
              type="textarea"
              label={intl.formatMessage({ id: 'EditListingDescriptionForm.parts', })}
              placeholder={intl.formatMessage({ id: 'EditListingDescriptionForm.partsPlaceholder', })}
            />
            <FieldTextInput
              id="depositConditions"
              name="depositConditions"
              className={css.description}
              type="text"
              label={depositConditionsLabel}
              placeholder={depositConditionsPlaceholder}
            />

            <FieldTextInput
              id="serviceDaysAmount"
              name="serviceDaysAmount"
              className={classNames(css.description, css.serviceDays)}
              type="number"
              label={intl.formatMessage({ id: 'EditListingDescriptionForm.serviceDaysAmount' })}
              placeholder={intl.formatMessage({ id: 'EditListingDescriptionForm.serviceDaysAmountPlaceholder' })}
              validate={(value) => nonNegativeNumber(intl.formatMessage({ id: 'EditListingDescriptionForm.serviceDaysAmountValidator' }), value)}
            />

            <div className={css.infoWrapper}>
              <IconInfo />
              <div>
                <p className={css.infoText}>
                  {intl.formatMessage({ id: 'EditListingDescriptionForm.serviceDaysAmountInfo' })}
                  <span>{serviceDaysProviderMessage}</span>
                </p>
                <p
                  className={css.infoText}>{intl.formatMessage({ id: 'EditListingDescriptionForm.serviceDaysInfo' })}</p>
              </div>
            </div>
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form>
        );
      }}
    />
  )
};

EditListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
  productCatalogList: propTypes.catalogProduct,
  userPublicData: object.isRequired,
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
