import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { arrayOf, bool, instanceOf, oneOf, shape, string } from 'prop-types';
import Decimal from 'decimal.js';

import { intlShape } from '../../../util/reactIntl';
import { ResponsiveImage } from '../..';
import { FormattedMessage } from '../../../util/reactIntl';
import { formatMoney } from '../../../util/currency';
import { createSlug } from '../../../util/urlHelpers';
import { propTypes } from '../../../util/types';

import { NamedLink } from '../../../components';

import css from './OrderDetails.css';

const format = (intl, date, isSameYear) => {
  const dateWithoutYear = intl.formatDate(date, {
    month: 'short',
    day: 'numeric',
  });

  const dateWithYear = intl.formatDate(date, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });

  return isSameYear ? dateWithoutYear : dateWithYear;
};

const OrderDetails = ({
  providerName,
  listings,
  attributes: { end, start, processState, code },
  intl,
  actualOrderedProducts,
  isOrder,
  transaction,
  transactionStatus
}) => {

  const transactionStatusAttributes = transactionStatus.find(
    ({ status }) => status === processState
  );

  const isSameYear = moment(new Date()).isSame(moment(start), 'year');

  return (
    <div className={css.container}>
      <div className={classNames(css.titleContainer, css.title)}>
        <p className={css.title}>
          <FormattedMessage id="DetailOrder.orderFrom" values={{ providerName }} />
          {code && <span className={css.code}><FormattedMessage id="OrderDetails.bookingNo" /> <span className={css.codeValue}>{code}</span></span>}
        </p>
        <div className={classNames(css.statusText, transactionStatusAttributes.class)}>
          <FormattedMessage id={transactionStatusAttributes.textStatus} />
        </div>
      </div>
      {
        isOrder && processState === 'pending-payment' &&
        <NamedLink name="PaymentPage" params={{ transactionId: transaction.id.uuid }} className={css.paymentLink}>
          <FormattedMessage id="InboxPage.pay" />
        </NamedLink>
      }
      <div className={css.mainContent}>
        {actualOrderedProducts.map(({ listingId, lineTotal, seats }) => {
          const listing = listings.find(({ id: { uuid } }) => uuid === listingId);
          const { uuid } = listing.id;
          const image = listing?.images[0];
          const { title, deleted } = listing.attributes;
          const params = { id: listingId, slug: createSlug(title) };
          return (
            <div
              className={
                seats ? css.productContainer : classNames(css.productContainer, css.overlay)
              }
              key={uuid}
            >
              {image && (
                <ResponsiveImage
                  rootClassName={css.rootForImage}
                  image={image}
                  alt={'product'}
                  variants={['square-small', 'square-small2x']}
                />
              )}
              <div className={css.productContent}>
                <p className={css.productName}>{title}</p>
                <div className={css.productDetails}>
                  <p className={css.text}>
                    <FormattedMessage id="DetailOrder.quantity" /> <b> {seats}</b>
                  </p>
                  <p className={css.text}>
                    <FormattedMessage id="DetailOrder.when" />{' '}
                    <b>
                      {format(intl, moment(start), isSameYear)} -{' '}
                      {format(intl, moment(end).subtract(1, 'days'), isSameYear)}
                    </b>
                  </p>
                  <p className={classNames(css.text, css.price)}>{formatMoney(intl, lineTotal)}</p>
                </div>
                {!deleted && <NamedLink className={css.text} name="ListingPage" params={params}>
                  <FormattedMessage id="DetailOrder.product" />
                </NamedLink>}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

OrderDetails.propTypes = {
  intl: intlShape,
  providerName: string.isRequired,
  attributes: shape({
    payinTotal: propTypes.money,
    payoutTotal: propTypes.money,
    processState: oneOf([
      'payment-expired',
      'pending-payment',
      'accepted',
      'picked-up',
      'cancelled',
      'delivered',
      'declined',
    ]),
    end: string.isRequired,
    start: string.isRequired,
    lineItems: arrayOf(
      shape({
        code: string.isRequired,
        includeFor: arrayOf(oneOf(['customer', 'provider'])).isRequired,
        quantity: instanceOf(Decimal),
        unitPrice: propTypes.money.isRequired,
        lineTotal: propTypes.money.isRequired,
        reversal: bool.isRequired,
      })
    ),
    transitions: arrayOf(propTypes.transition).isRequired,
    listings: arrayOf(propTypes.listing),
  }),
};

export default OrderDetails;
