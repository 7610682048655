import React, { useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';
import { formatMoney } from '../../../util/currency';

import css from './Summary.css';
import AddToUserModal from '../AddToUserModal';

const format = (intl, date) =>
  intl.formatDate(date, {
    weekday: 'short',
    month: 'short',
    year: 'numeric',
    day: 'numeric',
  });

const Summary = ({
  start,
  end,
  payinTotal,
  lineItems,
  cartListings,
  intl,
  handleSubmit,
  provider,
  id,
  totalNumberOfOrders,
  onAddCartToUser,
  isAddToUserAvailable
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const PRODUCT_CODE = 'day';
  const LONG_TERM_DISCOUNT = 'long-term-discount';

  const handleBook = () => {
    handleSubmit({
      start,
      end,
      cartListings,
      provider,
      cart: id,
      totalNumberOfOrders,
    });
  };

  const handleOpenUserModal = () => {
    setIsModalOpen(true);
  }

  const products = lineItems
    .filter(li => !!li)
    .map(({ code, listingId, quantity, lineTotal }) => {
      if (code === PRODUCT_CODE) {
        const listingItem = cartListings.find(
          ({
            listing: {
              id: { uuid },
            },
          }) => listingId === uuid
        );
        const title = listingItem.listing.attributes.title;
        return (
          <div className={css.rowContainer} key={listingId}>
            <p className={css.productText}>
              {title} x {quantity}{' '}
              <FormattedMessage id={quantity === 1 ? 'Summary.day' : 'Summary.days'} />
            </p>
            <p className={css.productText}>{formatMoney(intl, lineTotal)}</p>
          </div>
        );
      }
      {
        if (code === LONG_TERM_DISCOUNT) {
          return (
            <div className={classNames(css.rowContainer, css.discount)} key={LONG_TERM_DISCOUNT}>
              <p className={css.productText}>
                <FormattedMessage id="Summary.discountText" />
              </p>
              <p className={css.productText}>{formatMoney(intl, lineTotal)}</p>
            </div>
          );
        }
      }
    });

  const activeProgramPoints =
    process.env.REACT_APP_COUNTRY_ID === 'pl' ? (
      <div className={css.rowContainer}>
        <p className={css.productText}>
          <FormattedMessage id="Summary.textPoints" />
        </p>
        <p className={css.productText}>
          <FormattedMessage id="Summary.points" />
        </p>
      </div>
    ) : null;

  return (
    <div className={css.summaryCard}>
      <p className={css.title}>
        <FormattedMessage id="Summary.summaryTitle" />
      </p>
      <div className={css.productSection}>
        <div className={css.rowContainer}>
          <p className={css.productText}>
            <FormattedMessage id="Summary.bookingStartTitle" />
          </p>
          <p className={css.productText}>{format(intl, moment(start))}</p>
        </div>
        <div className={css.rowContainer}>
          <p className={css.productText}>
            <FormattedMessage id="Summary.bookingEndTitle" />
          </p>
          <p className={css.productText}>{format(intl, moment(end).subtract(1, 'days'))}</p>
        </div>
      </div>
      <div className={css.productSection}>{products}</div>
      <div className={css.togetherSection}>
        {activeProgramPoints}
        <div className={css.rowContainer}>
          <p className={css.togetherText}>
            <FormattedMessage id="Summary.together" />
          </p>
          <p className={css.togetherText}>{formatMoney(intl, payinTotal)}</p>
        </div>
      </div>
      <button className={css.bookButton} onClick={handleBook}>
        <FormattedMessage id="Summary.bookButton" />
      </button>
      { isAddToUserAvailable && <button className={css.addToUserButton} onClick={handleOpenUserModal}>
        <FormattedMessage id="Summary.addToUser" />
      </button> }
      <AddToUserModal
        id={id}
        isOpen={isModalOpen}
        onCloseModal={() => setIsModalOpen(false)}
        cartListings={cartListings}
        onAddCartToUser={onAddCartToUser}
      />
    </div>
  );
};

export default Summary;
