import React, { useEffect, useState } from 'react';
// import LineChart from './LineChart';
import { businessAnalysis } from '../../util/api';

import css from './BusinessTable.css';

const fillDates = (start, end, arr) => {
  let current = new Date(start).valueOf();
  const endStamp = new Date(end).valueOf();
  const existingDays = {};
  arr.forEach(item => {
    existingDays[item.date] = item;
  });
  const res = [];
  for (;;) {
    const date = new Date(current).toISOString().substr(0, 10);
    res.push(existingDays[date] || {date, seats: 0});
    if (current >= endStamp) {
      break;
    }
    current += 24*60*60*1000;
  }
  return res;
}


const UsageChart = ({ dates, query }) => {
  const [data, setData] = useState(null);
  useEffect(() => {
    const fetchUsageData = async () => {
      const result = await businessAnalysis({ start: dates.start, end: dates.end, query });
      setData(fillDates(dates.start, dates.end, result.grouped).map(x => ({x: x.date, y:x.seats})))
    }

    fetchUsageData();
  }, [dates.start, dates.end])

  if(!data) {
    return null
  } 

  return (
    <div className={css.chartWrapper}>
      Chart
      {/* <LineChart data={data} /> */}
    </div>
  );
}

export default UsageChart;