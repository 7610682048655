import { addMarketplaceEntities } from './marketplaceData.duck';
import { parse } from '../util/urlHelpers';
import config from '../config';

// ================ Action types ================ //

export const FETCH_PRODUCT_CATALOG_REQUEST =
  'app/productCatalog/FETCH_PRODUCT_CATALOG_REQUEST';
export const FETCH_PRODUCT_CATALOG_SUCCESS =
  'app/productCatalog/FETCH_PRODUCT_CATALOG_SUCCESS';
export const FETCH_PRODUCT_CATALOG_ERROR =
  'app/productCatalog/FETCH_PRODUCT_CATALOG_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchInProgress: false,
  fetchProductCatalogError: null,
  pagination: null,
  productCatalog: [],
};

export default function productCatalogsReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_PRODUCT_CATALOG_REQUEST:
      return { ...state, fetchInProgress: true, fetchProductCatalogError: null };
    case FETCH_PRODUCT_CATALOG_SUCCESS: {
      return {
        ...state,
        fetchInProgress: false,
        productCatalog: payload.data.data,
        pagination: payload.data.meta,
      };
    }
    case FETCH_PRODUCT_CATALOG_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchProductCatalogError: payload };

    default:
      return state;
  }
}
// ================ Action creators ================ //

const fetchProductCatalogRequest = () => ({ type: FETCH_PRODUCT_CATALOG_REQUEST });

const fetchProductCatalogSuccess = response => ({
  type: FETCH_PRODUCT_CATALOG_SUCCESS,
  payload: response,
});

const fetchProductCatalogError = e => ({
  type: FETCH_PRODUCT_CATALOG_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const CONSOLE_PAGE_SIZE = 100;

export const featProductCatalog = (params, search) => (dispatch, getState, sdk) => {
  const { page = 1, countryId = config.custom.countryId, phrase } = parse(search);
  const paginationParams = {
    page,
    per_page: CONSOLE_PAGE_SIZE,
  };
  const phraseParam = phrase ? { phrase } : undefined;

  const queryParams = {
    ...paginationParams,
    include: ['images', 'catalogItems.images'],
    countryId,
    ...phraseParam,
  };

  dispatch(fetchProductCatalogRequest());

  return sdk.newSdk.catalogProducts
    .query(queryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchProductCatalogSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchProductCatalogError(e));
      throw e;
    });
};
