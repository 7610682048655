import React, { Component } from 'react';
import { string, func, bool } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug, decodeLatLng } from '../../util/urlHelpers';
import { getDistanceInKilometers } from '../../util/maps';
import config from '../../config';
import { IconPinMap, NamedLink, ResponsiveImage } from '../../components';

import css from './ListingCard.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const getCoordinatesOfNearestStore = (deliveryToProviders, point) => {
  const distances = [];

  deliveryToProviders.forEach(item => {
    const lat = item.attributes?.location?.lat;
    const lng = item.attributes?.location?.lng;
    const distance =
      lat && lng && Math.round(getDistanceInKilometers({ lat, lng }, point) * 10) / 10;
    distances.push(distance);
  });

  const sortedDistances = distances.sort((a, b) => a - b);
  return sortedDistances[0];
};

const priceData = (price, intl) => {
  if (price) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = ({
  className,
  rootClassName,
  intl,
  listing,
  renderSizes,
  setActiveListing,
  locationCenter,
  isUserLocation,
}) => {
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price } = currentListing.attributes;
  const isSuperStore = !!listing.provider.attributes.superStore?.active;
  const isDeliveryToProviders = !!listing.provider.deliveryToProviders?.length;
  const point = locationCenter && decodeLatLng(locationCenter);
  const { lat, lng } = listing.provider?.attributes.location;
  let distance = false;

  if (isSuperStore && isDeliveryToProviders) {
    distance = point && getCoordinatesOfNearestStore(listing.provider.deliveryToProviders, point);
  } else {
    distance =
      point && lat && lng && Math.round(getDistanceInKilometers({ lat, lng }, point) * 10) / 10;
  }

  const slug = createSlug(title);
  const author = ensureUser(listing.provider);
  const authorName = author.attributes && author.attributes.name;
  const homeDelivery = author?.attributes.deliveryToHome;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const { formattedPrice, priceTitle } = priceData(price, intl);

  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ListingCard.perNight'
    : isDaily
    ? 'ListingCard.day'
    : 'ListingCard.perUnit';

  const homeDeliverText = homeDelivery ? <FormattedMessage id="ListingCard.homeDeliver" /> : ' ';
  const distanceText = isUserLocation ? 'ListingCard.distanceFromYou' : 'ListingCard.distance';

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      {/* <div>
        <p className={homeDeliver ? css.homeDeliverInfo : css.withoutHomeDeliver}>{homeDeliverText}</p>
      </div> */}
      <div
        className={css.threeToTwoWrapper}
        onMouseEnter={() => setActiveListing(currentListing.id)}
        onMouseLeave={() => setActiveListing(null)}
      >
        <div className={css.aspectWrapper}>
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={['scaled-medium']}
            sizes={renderSizes}
          />
        </div>
      </div>
      <div className={css.info}>
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          <div className={css.authorInfo}>
            <FormattedMessage id="ListingCard.hostedBy" values={{ authorName }} />
          </div>
        </div>
        <div className={css.price}>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice}
          </div>
          <div className={css.perUnit}>
            / <FormattedMessage id={unitTranslationKey} />
          </div>
        </div>
        {(distance || distance === 0) && (
          <div className={css.distanceContainer}>
            <IconPinMap />
            <p className={css.distanceInfo}>
              <FormattedMessage id={distanceText} values={{ distance }} />
            </p>
          </div>
        )}
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
  isUserLocation: false,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  isUserLocation: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
