import React from 'react';

import { required } from '../../util/validators';
import { FieldSelect } from '../../components';

import css from './EditListingDescriptionForm.css';

const CatalogProductSelectField = props => {
  const { name, id, catalogProducts, intl, form, markRequired, disabled } = props;
  const catalogProductLabel = intl.formatMessage({
    id: 'EditListingDescriptionForm.catalogProductLabel',
  });
  const catalogProductPlaceholder = intl.formatMessage({
    id: 'EditListingDescriptionForm.catalogProductPlaceholder',
  });
  const catalogProductRequired = required(
    intl.formatMessage({
      id: 'EditListingDescriptionForm.catalogProductRequired',
    })
  );

  const handelChange = (event) => {
    form.change('catalogProduct', event.target.value);
    form.change('catalogItem', '');
  }

  return catalogProducts ? (
    <FieldSelect
      className={css.category}
      name={name}
      id={id}
      label={catalogProductLabel}
      onChange={handelChange}
      markRequired={markRequired}
      disabled={disabled}
      validate={catalogProductRequired}
    >
      <option disabled value="">
        {catalogProductPlaceholder}
      </option>
      {catalogProducts.map(product => (
        <option key={product.id.uuid} value={product.id.uuid} >
          {product.attributes.modelCode} - {product.attributes.title}
        </option>
      ))}
    </FieldSelect>
  ) : null;
};

export default CatalogProductSelectField;
