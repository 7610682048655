import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import uniqBy from 'lodash/uniqBy';
import moment from 'moment';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { parse } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { storesSummary } from '../../util/api';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import {
  BusinessSummary,
  BusinessTable,
  DatePicker,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperBusinessSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  Page,
  UserNav,
} from '../../components';
import { TopbarContainer } from '..';

import { isScrollingDisabled } from '../../ducks/UI.duck';
import css from './AdminPage.css';

const date = new Date();
const currentYear = date.getFullYear();
const currentMonth =  date.getMonth();
const firstDay = new Date(currentYear, currentMonth, 2).toISOString().split('T')[0];
const lastDay = new Date().toISOString().split('T')[0];

export const BusinessDktPageComponent = props => {
  const {
    scrollingDisabled,
    intl,
    location,
    history
  } = props;

  const { start = firstDay, end = lastDay, prev_start, prev_end } = parse(location.search);
  const [data, setData] = useState(null);
  const [prevData, setPrevData] = useState(null);
  const [error, setError] = useState(null);

  const isComparisonOn = prev_start && prev_end;

  useEffect(() => {
    Promise.all([
      storesSummary({ start, end }),
      prev_start && prev_end ? storesSummary({ start: prev_start, end: prev_end }) : Promise.resolve({ data: null })
    ])
    .then(res=> {
        setData(res[0].data);
        setPrevData(res[1].data);
      })
    .catch(err => {
      console.log(err)
      setError('Nie udało się pobrać informacji. Spróbuj ponownie później.')
    })
  }, [start, end, prev_start, prev_end])

  const title = 'Administrator Rent';

  const handleDateRangeSubmit = ({ start, end, prevStart, prevEnd }) => {
    const prevDates = prevStart && prevEnd ? { prev_start: prevStart, prev_end: prevEnd } : {};
    history.push(
      createResourceLocatorString(
        'AdminPage',
        routeConfiguration(),
        {},
        { start, end, ...prevDates }
      )
    );
  }

  const removeComparison = () => {
    handleDateRangeSubmit({ start, end })
  }

  const dataArray = data ? data.grouped.map(s => ({ ...s, label: s.providerName })) : [];
  const prevDataArray = prevData ? prevData.grouped.map(s => ({ ...s, label: s.providerName })) : [];

  const concatenatedArrays = uniqBy([...dataArray, ...prevDataArray], 'providerId');

  const concatenatedData = concatenatedArrays.map(x => {
    const current = dataArray.find(s => s.providerId === x.providerId);
    const prev = prevDataArray.find(s => s.providerId === x.providerId);

    return {
      label: x.label,
      providerId: x.providerId,
      providerName: x.providerName,
      data: [
        { current: current ? (current.incomeTotal / 100).toFixed(2) : 0, prev: prev ? (prev.incomeTotal / 100).toFixed(2) : 0 },
        { current: current ? (current.payinTotal / 100).toFixed(2) : 0, prev: prev ? (prev.payinTotal / 100).toFixed(2) : 0 },
        { current: current ? current.transactionsCount : 0, prev: prev ? prev.transactionsCount : 0 },
      ],
    }
  });

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="AdminPage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
          <UserNav selectedPageName="AdminPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperBusinessSideNav currentTab="AdminPageTab" />
        <LayoutWrapperMain className={css.colorBackground}>
          <div className={css.content}>
            {data && <small className={css.lastUpdate}>Dane aktualne na {data.all.lastDate}</small>}
            <div className={css.header}>
              <h1 className={css.title}>
                Administrator Rent
              </h1>
              <div className={css.datePickerContainer}>
                <DatePicker
                  onSubmit={handleDateRangeSubmit}
                  value={{ startDate: start, endDate: end, prevStartDate: prev_start, prevEndDate: prev_end }}
                  showComparison
                />
                {
                  isComparisonOn &&
                  <div className={css.comparisonDates}>
                    {`vs ${intl.formatDate(moment(prev_start), { month: 'long', year: 'numeric', day: 'numeric' })} - ${intl.formatDate(moment(prev_end), { month: 'long', year: 'numeric', day: 'numeric' })}`}
                    <div className={css.removeComparison} onClick={removeComparison}>&#10005;</div>
                  </div>
                }
              </div>
            </div>
            {
              error
            }
            {
              data &&
                <BusinessSummary
                  currentData={{
                    payinTotal: data.all.payinTotal,
                    transactionsCount: data.all.transactionsCount,
                    payoutTotal: data.all.payoutTotal,
                    payinAvg: data.all.payinAvg,
                    payinMax: data.all.payinMax,
                    avgDuration: data.all.avgDuration,
                    itemsCount: data.all.itemsCount,
                  }}
                  previousData={prevData && {
                    payinTotal: prevData.all.payinTotal,
                    transactionsCount: prevData.all.transactionsCount,
                    payoutTotal: prevData.all.payoutTotal,
                    payinAvg: prevData.all.payinAvg,
                    payinMax: prevData.all.payinMax,
                    avgDuration: prevData.all.avgDuration,
                    itemsCount: prevData.all.itemsCount,
                  }}
                />
            }
            { 
              data && data.grouped &&
                <BusinessTable
                  rows={concatenatedData}
                  isComparisonOn={isComparisonOn}
                  sortBy={0}
                  dataHeaders={[
                    { primary: 'Obrót cały', secondary: '(zł)' },
                    { primary: 'Obrót stripe', secondary: '(zł)' },
                    { primary: 'Wypożyczenia', secondary: '(liczba)' },
                  ]}
                />
            }  
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

BusinessDktPageComponent.defaultProps = {
  saveEmailError: null,
  savePhoneNumberError: null,
  currentUser: null,
  sendVerificationEmailError: null,
};

const { bool } = PropTypes;

BusinessDktPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  // Topbar needs user info.
  const { currentUser } = state.user;
  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({});

const BusinessDktPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl,
  withRouter
)(BusinessDktPageComponent);

export default BusinessDktPage;
