import React from 'react';

import { FormattedMessage } from '../../../util/reactIntl';
import { IconWarning, NamedLink } from '../../../components';

import css from './WarningAcceptTerms.css';

const WarningAcceptTerms = () => {
  return (
    <div className={css.warningContainer}>
      <div className={css.warningContent}>
        <IconWarning variant="error" />
        <p className={css.warningText}>
          <FormattedMessage id="CheckoutPage.noTermsConfirmed" />{' '}
          <NamedLink
            className={css.linkToProfile}
            name="ProfileSettingsPage"
            to={{
              search: `?cartId=cart`,
            }}
          >
            <FormattedMessage id="CheckoutPage.here" />
          </NamedLink>
        </p>
      </div>
    </div>
  );
};

export default WarningAcceptTerms;
