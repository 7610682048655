import React from 'react';
import { required } from '../../util/validators';
import { FieldSelect } from '../../components';

import css from './EditListingDescriptionForm.css';

const CustomProviderField = props => {
  const { name, id, providers, intl, markRequired, disabled } = props;
  const providerLabel = intl.formatMessage({
    id: 'EditListingDescriptionForm.providerLabel',
  });
  const providerPlaceholder = intl.formatMessage({
    id: 'EditListingDescriptionForm.providerPlaceholder',
  });
  const providerRequired = required(
    intl.formatMessage({
      id: 'EditListingDescriptionForm.providerRequired',
    })
  );
  return providers ? (
    <FieldSelect
      className={css.category}
      name={name}
      id={id}
      label={providerLabel}
      validate={providerRequired}
      markRequired={markRequired}
      disabled={disabled}
    >
      <option disabled value="">
        {providerPlaceholder}
      </option>
      {providers.map(c => (
        <option key={c.key} value={c.key}>
          {c.label}
        </option>
      ))}
    </FieldSelect>
  ) : null;
};

export default CustomProviderField;
