import omit from 'lodash/omit';
import { storableError } from '../../util/errors';
import * as log from '../../util/log';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';

const requestAction = actionType => params => ({ type: actionType, payload: { params } });

const successAction = actionType => result => ({ type: actionType, payload: result.data });

const errorAction = actionType => error => ({ type: actionType, payload: error, error: true });

// ================ Action types ================ //

export const MARK_TAB_UPDATED = 'app/EditItemPage/MARK_TAB_UPDATED';
export const CLEAR_UPDATED_TAB = 'app/EditItemPage/CLEAR_UPDATED_TAB';

export const CREATE_ITEM_DRAFT_REQUEST = 'app/EditItemPage/CREATE_ITEM_DRAFT_REQUEST';
export const CREATE_ITEM_DRAFT_SUCCESS = 'app/EditItemPage/CREATE_ITEM_DRAFT_SUCCESS';
export const CREATE_ITEM_DRAFT_ERROR = 'app/EditItemPage/CREATE_ITEM_DRAFT_ERROR';

export const PUBLISH_ITEM_REQUEST = 'app/EditItemPage/PUBLISH_ITEM_REQUEST';
export const PUBLISH_ITEM_SUCCESS = 'app/EditItemPage/PUBLISH_ITEM_SUCCESS';
export const PUBLISH_ITEM_ERROR = 'app/EditItemPage/PUBLISH_ITEM_ERROR';

export const UPDATE_ITEM_REQUEST = 'app/EditItemPage/UPDATE_ITEM_REQUEST';
export const UPDATE_ITEM_SUCCESS = 'app/EditItemPage/UPDATE_ITEM_SUCCESS';
export const UPDATE_ITEM_ERROR = 'app/EditItemPage/UPDATE_ITEM_ERROR';

export const SHOW_ITEMS_REQUEST = 'app/EditItemPage/SHOW_ITEMS_REQUEST';
export const SHOW_ITEMS_SUCCESS = 'app/EditItemPage/SHOW_ITEMS_SUCCESS';
export const SHOW_ITEMS_ERROR = 'app/EditItemPage/SHOW_ITEMS_ERROR';

export const UPLOAD_IMAGE_REQUEST = 'app/EditItemPage/UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'app/EditItemPage/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'app/EditItemPage/UPLOAD_IMAGE_ERROR';

export const UPDATE_IMAGE_ORDER = 'app/EditItemPage/UPDATE_IMAGE_ORDER';

export const REMOVE_ITEM_IMAGE = 'app/EditItemPage/REMOVE_ITEM_IMAGE';

export const FETCH_CATALOG_PRODUCTS_REQUEST = 'app/EditItemPage/FETCH_CATALOG_PRODUCTS_REQUEST';
export const FETCH_CATALOG_PRODUCTS_SUCCESS = 'app/EditItemPage/FETCH_CATALOG_PRODUCTS_SUCCESS';
export const FETCH_CATALOG_PRODUCTS_ERROR = 'app/EditItemPage/FETCH_CATALOG_PRODUCTS_ERROR';

// ================ Reducer ================ //

const initialState = {
  createItemError: null,
  publishingListing: null,
  publishListingError: null,
  updateItemError: null,
  showItemError: null,
  uploadImageError: null,
  createItemInProgress: false,
  submittedItemId: null,
  redirectToListing: false,
  images: {},
  imageOrder: [],
  removedImageIds: [],
  listingDraft: null,
  updatedTab: null,
  updateItemInProgress: false,
  catalogProducts: [],
  fetchCatalogProductsError: null,
  fetchInProgress: true,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case MARK_TAB_UPDATED:
      return { ...state, updatedTab: payload };
    case CLEAR_UPDATED_TAB:
      return { ...state, updatedTab: null, updateItemError: null };

    case CREATE_ITEM_DRAFT_REQUEST:
      return {
        ...state,
        createItemInProgress: true,
        createItemError: null,
        submittedItemId: null,
        listingDraft: null,
      };

    case CREATE_ITEM_DRAFT_SUCCESS:
      return {
        ...state,
        createItemInProgress: false,
        submittedItemId: payload.data.id,
        listingDraft: payload.data,
      };
    case CREATE_ITEM_DRAFT_ERROR:
      return {
        ...state,
        createItemInProgress: false,
        createItemError: payload,
      };

    case PUBLISH_ITEM_REQUEST:
      return {
        ...state,
        publishingListing: payload.listingId,
        publishListingError: null,
      };
    case PUBLISH_ITEM_SUCCESS:
      return {
        ...state,
        redirectToListing: true,
        publishingListing: null,
        createItemError: null,
        updateItemError: null,
        showItemError: null,
        uploadImageError: null,
        createItemInProgress: false,
        updateItemInProgress: false,
      };
    case PUBLISH_ITEM_ERROR: {
      return {
        ...state,
        publishingListing: null,
        publishListingError: {
          listingId: state.publishingListing,
          error: payload,
        },
      };
    }

    case UPDATE_ITEM_REQUEST:
      return { ...state, updateItemInProgress: true, updateItemError: null };
    case UPDATE_ITEM_SUCCESS:
      return { ...state, updateItemInProgress: false };
    case UPDATE_ITEM_ERROR:
      return { ...state, updateItemInProgress: false, updateItemError: payload };

    case SHOW_ITEMS_REQUEST:
      return { ...state, showItemError: null };
    case SHOW_ITEMS_SUCCESS:
      return {...state };

    case SHOW_ITEMS_ERROR:
      console.error(payload);
      return { ...state, showItemError: payload, redirectToListing: false };

    case UPLOAD_IMAGE_REQUEST: {
      const images = {
        ...state.images,
        [payload.params.id]: { ...payload.params },
      };
      return {
        ...state,
        images,
        imageOrder: state.imageOrder.concat([payload.params.id]),
        uploadImageError: null,
      };
    }
    case UPLOAD_IMAGE_SUCCESS: {
      const { id, imageId } = payload;
      const file = state.images[id].file;
      const images = { ...state.images, [id]: { id, imageId, file } };
      return { ...state, images };
    }
    case UPLOAD_IMAGE_ERROR: {
      const { id, error } = payload;
      const imageOrder = state.imageOrder.filter(i => i !== id);
      const images = omit(state.images, id);
      return { ...state, imageOrder, images, uploadImageError: error };
    }
    case UPDATE_IMAGE_ORDER:
      return { ...state, imageOrder: payload.imageOrder };

    case REMOVE_ITEM_IMAGE: {
      const id = payload.imageId;
      const removedImageIds = state.images[id]
        ? state.removedImageIds
        : state.removedImageIds.concat(id);
      const images = omit(state.images, id);
      const imageOrder = state.imageOrder.filter(i => i !== id);

      return { ...state, images, imageOrder, removedImageIds };
    }

    case FETCH_CATALOG_PRODUCTS_REQUEST:
      return { ...state, fetchCatalogProductsError: null };
    case FETCH_CATALOG_PRODUCTS_SUCCESS:
      return { ...state, catalogProducts: payload.data, fetchCatalogProductsError: null };
    case FETCH_CATALOG_PRODUCTS_ERROR:
      return { ...state, fetchCatalogProductsError: payload };

    case 'LOADING_DATA':
      return { ...initialState };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const markTabUpdated = tab => ({
  type: MARK_TAB_UPDATED,
  payload: tab,
});

export const clearUpdatedTab = () => ({
  type: CLEAR_UPDATED_TAB,
});

export const updateImageOrder = imageOrder => ({
  type: UPDATE_IMAGE_ORDER,
  payload: { imageOrder },
});

export const removeItemImage = imageId => ({
  type: REMOVE_ITEM_IMAGE,
  payload: { imageId },
});

export const fetchCatalogProductsRequest = requestAction(FETCH_CATALOG_PRODUCTS_REQUEST);
export const fetchCatalogProductsSuccess = successAction(FETCH_CATALOG_PRODUCTS_SUCCESS);
export const fetchCatalogProductsError = errorAction(FETCH_CATALOG_PRODUCTS_ERROR);

// All the action creators that don't have the {Success, Error} suffix
// take the params object that the corresponding SDK endpoint method
// expects.

// SDK method: ownListings.create
export const createItem = requestAction(CREATE_ITEM_DRAFT_REQUEST);
export const createItemSuccess = successAction(CREATE_ITEM_DRAFT_SUCCESS);
export const createItemError = errorAction(CREATE_ITEM_DRAFT_ERROR);

// SDK method: ownListings.update
export const updateItem = requestAction(UPDATE_ITEM_REQUEST);
export const updateItemSuccess = successAction(UPDATE_ITEM_SUCCESS);
export const updateItemError = errorAction(UPDATE_ITEM_ERROR);

// SDK method: ownListings.show
export const showItem = requestAction(SHOW_ITEMS_REQUEST);
export const showItemSuccess = successAction(SHOW_ITEMS_SUCCESS);
export const showItemError = errorAction(SHOW_ITEMS_ERROR);

// SDK method: images.upload
export const uploadImage = requestAction(UPLOAD_IMAGE_REQUEST);
export const uploadImageSuccess = successAction(UPLOAD_IMAGE_SUCCESS);
export const uploadImageError = errorAction(UPLOAD_IMAGE_ERROR);

// ================== Helpers ================ //

/**
 * Function to update all resources with a specific old type to a new type in a JSON object API response.
 * 
 * @param {Object} jsonObject - The JSON object containing the resources.
 * @param {string} oldType - The old type to be updated.
 * @param {string} newType - The new type to set.
 * @returns {Object} - The updated JSON object.
 */
export function updateResourceType(jsonObject, oldType, newType) {
  // Function to update type in a single item
  const updateTypeInItem = (item) => {
    if (item.type === oldType) {
      item.type = newType;
    }
    // Update relationships if they exist
    if (item.relationships) {
      Object.values(item.relationships).forEach(relationship => {
        if (relationship.data) {
          if (relationship.data.type === oldType) {
            relationship.data.type = newType;
          }
          // Handle relationship data arrays
          if (Array.isArray(relationship.data)) {
            relationship.data.forEach(relItem => {
              if (relItem.type === oldType) {
                relItem.type = newType;
              }
            });
          }
        }
      });
    }
  };

  // Function to update type in an array of items
  const updateTypeInArray = (array) => {
    array.forEach(item => updateTypeInItem(item));
  };

  // Update type in 'data' (object or array)
  if (Array.isArray(jsonObject.data)) {
    updateTypeInArray(jsonObject.data.data);
  } else if (typeof jsonObject.data === 'object' && jsonObject.data !== null) {
    updateTypeInItem(jsonObject.data.data);
  }

  // Update type in 'included' array
  if (Array.isArray(jsonObject.data.included)) {
    updateTypeInArray(jsonObject.data.included);
  }

  return jsonObject;
}

// ================ Thunk ================ //

export function requestShowItem(actionPayload) {
  return (dispatch, getState, sdk) => {
    dispatch(showItem(actionPayload));
    return sdk.newSdk.catalogItems
      .show({ ...actionPayload, include: [ 'catalogProduct', 'images'] })
      .then(response => {
        dispatch(addMarketplaceEntities(updateResourceType(response, 'catalog-product', 'reference-product')));
        dispatch(showItemSuccess(updateResourceType(response, 'catalog-product', 'reference-product')));
        return response;
      })
      .catch(e => {
        console.log(e)
        dispatch(showItemError(storableError(e)))
      });
  };
}

// Thunk to fetch catalog products
export function requestFetchCatalogProducts() {
  return (dispatch, getState, sdk) => {
    dispatch(fetchCatalogProductsRequest());
    return sdk.newSdk.catalogProducts
      .query({ countryId: 'pl', include: ['images'] })
      .then(response => {
        dispatch(addMarketplaceEntities(response));
        dispatch(fetchCatalogProductsSuccess(response));
        return response;
      })
      .catch(e => {
        console.log(e)
        dispatch(fetchCatalogProductsError(storableError(e)));
      });
  };
}

// Thunk to create item
export function requestCreateItem(data) {
  return (dispatch, getState, sdk) => {
    dispatch(createItem(data));

    const queryParams = {
      expand: true,
      include: ['images', 'catalogProduct'],
    };

    return sdk.newSdk.catalogItems
      .create(data, queryParams)
      .then(async response => {
        const id = response.data.data.id.uuid;

        dispatch(addMarketplaceEntities(response));

        dispatch(createItemSuccess(response));
        const payload = {
          id,
          include: ['images', 'catalogProduct'],
        };
        await dispatch(requestShowItem(payload));
        return response;
      })
      .catch(e => {
        log.error(e, 'create-item-draft-failed', { listingData: data });
        return dispatch(createItemError(storableError(e)));
      });
  };
}

// Thunk to upload image
export function requestImageUpload(actionPayload) {
  return (dispatch, getState, sdk) => {
    const id = actionPayload.id;
    dispatch(uploadImage(actionPayload));
    return sdk.newSdk.images
      .upload({ file: actionPayload.file })
      .then(resp => dispatch(uploadImageSuccess({ data: { id, imageId: resp.data.data.id } })))
      .catch(e => dispatch(uploadImageError({ id, error: storableError(e) })));
  };
}

// Thunk to update item
export function requestUpdateItem(data) {
  return (dispatch, getState, sdk) => {
    dispatch(updateItem(data));
    const { id, catalogProductId, ...restData } = data;
    let updateResponse;

    return sdk.newSdk.catalogItems
      .update({ id: id, ...restData, include: ['images', 'catalogProduct'] })
      .then(response => {
        updateResponse = response;
        const payload = {
          id: id,
          include: ['images', 'catalogProduct'],
        };
        return dispatch(requestShowItem(payload));
      })
      .then(() => {
        dispatch(updateItemSuccess(updateResponse));
        return updateResponse;
      })
      .catch(e => {
        log.error(e, 'update-item-failed', { listingData: data });
        return dispatch(updateItemError(storableError(e)));
      });
  };
}

// Load data for EditItemPage
export const loadData = params => (dispatch, getState, sdk) => {
  const { id, type, tab } = params;

  if (type === 'new') {
    // Fetch catalog products for new item creation
    return Promise.all([dispatch(fetchCurrentUser()), dispatch(requestFetchCatalogProducts())])
      .then(response => {
        return response;
      })
      .catch(e => {
        throw e;
      });
  }

  // Fetch existing item and catalog products
  return Promise.all([
    dispatch(requestShowItem({ id })),
    dispatch(fetchCurrentUser()),
    dispatch(requestFetchCatalogProducts()),
  ])
    .then(response => {
      return response;
    })
    .catch(e => {
      throw e;
    });
};
