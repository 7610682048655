import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import { Avatar } from '../../../components';
import { NamedLink, Button } from '../../../components';
import { FormattedMessage } from '../../../util/reactIntl';
import { stringify } from '../../../util/urlHelpers';

import css from './UserItem.css';

const UserItem = ({ firstName, lastName, language, email, createdAt, userId, banned, updateUser, updateUserInProgress }) => {
  const [isClicked, setIsClicked] = useState(false); 
  
  const handleBanned = () => {
    setIsClicked(true)
    updateUser({ id: userId, banned: !banned });
  };

  useEffect(() => {
    !updateUserInProgress && setIsClicked(false);
  }, [updateUserInProgress])

  return (
    <li className={css.listItem}>
      <div className={css.item}>
        <div className={css.itemAvatar}>
          <Avatar />
        </div>
        <div className={css.itemDetails}>
          <div className={css.itemNameDetails}>
            <p className={classNames(css.text, css.name)}>
              <span  className={css.nameText}>{firstName} {lastName}</span>
              {banned && <span className={css.bannedText}><FormattedMessage id="Console.banned" /></span>}
            </p>
            <p className={css.text}>{email}</p>
            <p className={css.text}>{language}</p>
            <NamedLink
              className={css.link}
              name="ConsoleTransactionPage"
              params={{ tab: 'transactions' }}
              to={{ search: stringify({ userId }) }}
            >
              <FormattedMessage id="Console.transactionsTabTitle" />
            </NamedLink>
          </div>
          <div className={css.itemBlocking}>
            <p className={css.itemDateDetails}>{createdAt}</p>
            <Button onClick={handleBanned} className={css.bannedButton} inProgress={updateUserInProgress && isClicked}>
              <FormattedMessage id={banned ? 'Console.unblock' : 'Console.block'} />
            </Button>
          </div>
        </div>
      </div>
    </li>
  );
};

UserItem.defaultProps = {
  firstName: null,
  lastName: null,
  language: null,
  email: null,
  createdAt: null,
  userId: null,
};

UserItem.propTypes = {
  firstName: string.isRequired,
  lastName: string.isRequired,
  language: string.isRequired,
  email: string.isRequired,
  createdAt: string.isRequired,
  userId: string.isRequired,
};

export default UserItem;
