import React, { Component } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { obfuscatedCoordinates } from '../../util/maps';
import { Map } from '../../components';
import config from '../../config';

import css from './ListingPage.css';

const REGEX = /[\w\słŁąćęńóśźżĄĆĘŃÓŚŹŻ]+,\s+\d{2}-\d{3}\s+([\w\słŁąćęńóśźżĄĆĘŃÓŚŹŻ-]+),\s+[A-Za-z]+/;

class SectionMapMaybe extends Component {
  constructor(props) {
    super(props);
    this.state = { isStatic: true };
  }

  render() {
    const { listing, className, rootClassName, geolocation, publicData, listingId, authorMetadata, listingPublicData, superStore } = this.props;
    const classes = classNames(rootClassName || css.sectionMap, className);

    if (!geolocation) {
      return null;
    }

    if (listing.attributes.deliveryToHome || listing.attributes.deliveryToProviders) {
      return (
        <div className={classes}>
          <h2 className={css.locationTitle}>
            <FormattedMessage id="ListingPage.locationTitle" />
          </h2>
          {
            listing.attributes.deliveryToHome && <p className={css.homeDelivery}><FormattedMessage id="ListingPage.homeDelivery" /></p>
          }
          {
            (listing.attributes.deliveryToHome && listing.attributes.deliveryToProviders) && <p className={css.divider}>lub</p>
          }
          {
            listing.attributes.deliveryToProviders && (
              <div className={css.delivery}>
                <h3>Ten produkt odbierzesz w sklepie:</h3>
                <div>
                  {
                    listing.provider?.deliveryToProviders?.sort((a, b) => {
                      const matchA = a.attributes.location?.address?.match(REGEX);
                      const matchB = b.attributes.location?.address?.match(REGEX);
                      const mA = matchA ? matchA[1] : '';
                      const mB = matchB ? matchB[1] : '';
                      if (mA < mB) {
                        return -1;
                      }
                      if (mA > mB) {
                        return 1;
                      }
                      return 0;
                    }).map((provider, index) => (
                      <div key={index}>
                        <p className={css.deliveryProvider}>{provider.attributes.name}</p>
                        <p className={css.deliveryAddress}>{provider.attributes.location?.address}</p>
                      </div>
                    ))

                  }
                </div>
              </div>  
            )
          }
        </div>
      )
    }

    const address = geolocation.address;
    const cacheKey = listingId ? `${listingId.uuid}_${geolocation.lat}_${geolocation.lng}` : null;

    const mapProps = config.maps.fuzzy.enabled
      ? { obfuscatedCenter: obfuscatedCoordinates(geolocation, cacheKey) }
      : { address, center: geolocation };
    const map = <Map {...mapProps} useStaticMap={this.state.isStatic} />;

    const superStoreDeliveryLocationsInfo = superStore ? (
      <div>
        <p className={css.pickupLocationHeader}><FormattedMessage id="SectionMapMaybe.placeOfAvailability" />{': '}</p>
        {/* <ul className={css.pickupLocationList}>
          {authorMetadata.super_store.locations.map(loc => <li key={loc} >{loc.name}</li>)}
        </ul> */}
        <p className={css.pickupLocationInfo}><FormattedMessage id="SectionMapMaybe.store" /></p>
      </div>
    ) : null;

    const homeDeliveryInfo = (
      <div>
        <p className={css.pickupLocationHeader}><FormattedMessage id="SectionMapMaybe.courierInformation" /></p>
        <p className={css.pickupLocationInfo}><FormattedMessage id="SectionMapMaybe.deliveryAddress" /></p>
      </div>
    );

    const displayLocation = () => {
      if (authorMetadata && authorMetadata.super_store && authorMetadata.super_store.active) return (
        <div>
          {listingPublicData.homeDelivery ? homeDeliveryInfo : superStoreDeliveryLocationsInfo}
        </div>
      );

      return this.state.isStatic ? (
        <button
          className={css.map}
          onClick={() => {
            this.setState({ isStatic: false });
          }}
        >
          {address}
          {map}
        </button>
      ) : (
        <div className={css.map}>
          {address}
          {map}
        </div>
      )
    }

    return (
      <div className={classes}>
        <h2 className={css.locationTitle}>
          <FormattedMessage id="ListingPage.locationTitle" />
        </h2>
        {displayLocation()}
      </div>
    );
  }
}

SectionMapMaybe.defaultProps = {
  rootClassName: null,
  className: null,
  geolocation: null,
  listingId: null,
};

SectionMapMaybe.propTypes = {
  rootClassName: string,
  className: string,
  geolocation: propTypes.latlng,
  listingId: propTypes.uuid,
};

export default SectionMapMaybe;
