import React, { useState } from 'react';
import classNames from 'classnames';
import { array, arrayOf, bool, func, number, string } from 'prop-types';
import moment from 'moment';

import { injectIntl, intlShape } from '../../util/reactIntl';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureTransaction } from '../../util/data';
import { propTypes } from '../../util/types';
import {
  txRoleIsCustomer,
  txIsDelivered,
  getUserTxRole,
  txRoleIsProvider,
} from '../../util/transaction';
import { printPDF } from './printPDF';

import SendMessageSection from './SendMessageSection/SendMessageSection';
import OrderDetails from './OrderDetails/OrderDetails';
import OrderInfo from './OrderInfo/OrderInfo';
import ActionButtons from './ActionButtons/ActionButtons';
import ReviewModal from './ReviewModal/ReviewModal';
import FeedSection from './FeedSection/FeedSection';
import PartialCancelModal from './PartialCancelModal/PartialCancelModal';
import DeliveryStateModal from './DeliveryStateModal/DeliveryStateModal';
import {
  InlineTextButton,
  UserDisplayName,
  IconArrowHead,
  NamedLink,
  Button,
  IconTick,
  SecondaryButton,
  LogoMedicoverSport, WarningComponent,
} from '..';
import { setOrderedProducts } from './setOrderedProducts';
import ProlongationModal from './ProlongationModal/ProlongationModal';
import ProlongGroupSection from './ProlongGroupSection/ProlongGroupSection';

import css from './TransactionPanel.css';


const transactionStatus = [
  { status: 'payment-expired', textStatus: 'DetailOrder.paymentExpired', class: css.expired },
  { status: 'pending-payment', textStatus: 'DetailOrder.pendingPayment', class: css.pending },
  { status: 'accepted', textStatus: 'DetailOrder.inProgress', class: css.inProgress },
  { status: 'picked-up', textStatus: 'DetailOrder.inProgress', class: css.inProgress },
  { status: 'cancelled', textStatus: 'DetailOrder.cancelled', class: css.cancelled },
  { status: 'delivered', textStatus: 'DetailOrder.delivered', class: css.delivered },
  { status: 'declined', textStatus: 'DetailOrder.cancelled', class: css.cancelled },
];

const TransactionPanelComponent = ({
  transaction,
  intl,
  transactionRole,
  acceptInProgress,
  declineInProgress,
  pickupInProgress,
  cancelInProgress,
  completeNotPickedupInProgress,
  completeInProgress,
  acceptSaleError,
  declineSaleError,
  pickupSaleError,
  completeSaleError,
  onAcceptSale,
  onDeclineSale,
  onPickupSale,
  onCompleteNotPickedupSale,
  onCompleteSale,
  onCancelSale,
  sendReviewError,
  sendReviewInProgress,
  onManageDisableScrolling,
  onSendReview,
  review,
  currentUser,
  currentUserHasListings,
  currentUserProviderId,
  fetchMessagesError,
  fetchMessagesInProgress,
  initialMessageFailed,
  oldestMessagePageFetched,
  messages,
  totalMessagePages,
  onShowMoreMessages,
  sendMessageInProgress,
  sendMessageError,
  onSendMessage,
  partialCancelInProgress,
  partialCancelError,
  onPartialCancelOrder,
  partialCancelSuccess,
  pageName,
  onUpdateTransaction,
  reviewNeeded,
  onChangeDeliveryState,
  changeDeliveryStateInProgress,
  canBeProlongedTo,
  callSetInitialValues,
  onInitializeCardPaymentData,
  history
}) => {
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(!!reviewNeeded);
  const [isPartialCancelModalOpen, setIsPartialCancelModalOpen] = useState(false);
  const [isDeliveryStateModalOpen, setIsDeliveryStateModalOpen] = useState(false);
  const [isProlongationModalOpen, setIsProlongationModalOpen] = useState(false);
  const [reviewSubmitted, setReviewSubmitted] = useState(false);
  const providerName = transaction.provider.attributes.name;
  const listings = transaction.listings;
  const isCustomerLoaded = !!transaction.user.id;
  const isCustomerBanned = isCustomerLoaded && transaction.user.attributes.banned;
  const isProvider = transactionRole === 'provider';
  const ownRole = getUserTxRole(currentUser.id, transaction);
  const { processState: status, lineItems, transitions } = transaction.attributes;
  const showSaleButtons = ['request', 'accepted', 'picked-up'].includes(status)
    ? isProvider && !isCustomerBanned
    : null;
  const customer = transaction.user;
  const provider = transaction.provider;
  const { countryId, isDecathlon } = provider.attributes;
  const displayProtocolPrinter = isProvider && isDecathlon && countryId === 'pl';
  const isProtocolButton =
    !['delivered', 'cancelled', 'payment-expired'].includes(status) && isProvider && displayProtocolPrinter;
  const displayName = txRoleIsProvider(ownRole) ? (
    <UserDisplayName user={customer} intl={intl} nameToDisplay={customer.attributes.displayName} />
  ) : (
    <UserDisplayName user={provider} intl={intl} nameToDisplay={provider.attributes.name} />
  );

  const expiredProducts = status === "payment-expired" || status === "pending-payment" ? lineItems.filter(item => item?.code === 'day' && !item.reversal) : null

  const actualOrderedProducts =
    lineItems?.length && transitions?.length && setOrderedProducts(lineItems, transitions);

  const sumSeats = actualOrderedProducts.reduce((accumulator, { seats }) => {
    accumulator += seats;
    return accumulator;
  }, 0);

  const isPartialCancelButton =
    ['accepted', 'picked-up'].includes(status) && isProvider && sumSeats > 1;

  const closeRateModal = () => {
    setIsReviewModalOpen(false);
    document.body.style.overflow = 'unset';
  };

  const openRateModal = () => {
    setIsReviewModalOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const openProlongationModal = () => {
    setIsProlongationModalOpen(true);
    // document.body.style.overflow = 'hidden';
  }

  const closeProlongationModal = () => {
    setIsProlongationModalOpen(false);
    // document.body.style.overflow = 'unset';
  }

  const closePartialCancelModal = () => {
    setIsPartialCancelModalOpen(false);
    document.body.style.overflow = 'unset';
  };

  const openPartialCancelModal = () => {
    setIsPartialCancelModalOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const closeDeliveryStateModal = () => {
    setIsDeliveryStateModalOpen(false);
    document.body.style.overflow = 'unset';
  };

  const openDeliveryStateModal = () => {
    setIsDeliveryStateModalOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const closeProlongationModalOpen = () => {
    setIsProlongationModalOpen(false);
    document.body.style.overflow = 'unset';
  };

  const onSubmitReview = values => {
    const currentTransaction = ensureTransaction(transaction);
    const { reviewRating, reviewContent } = values;
    const rating = Number.parseInt(reviewRating, 10);
    onSendReview(transactionRole, currentTransaction, rating, reviewContent)
      .then(response => {
        closeRateModal();
        setReviewSubmitted(true);
      })
      .catch(e => {
        // Do nothing.
      });
  };

  const protocolFile = transaction.attributes.documents.find(d => d.type === 'protocol');

  const successDetails = pageName === 'OrderDetailsSuccessPage' ? <>
    <div className={css.successContainer}>
      <div className={css.successContent}>
        <IconTick />
        <p className={css.successText}>
          <FormattedMessage id="TransactionPage.success" />{' '}
        </p>
      </div>
    </div>
  </> : null;

  const isAdmin = currentUser.attributes.isAdmin;
  const isProlongGroup = transaction && transaction.prolongGroup.length;

  const computeDeliveryState = (deliveryState) => {
    if (deliveryState === 'not-delivered') return 'oczekuje na wysyłkę do sklepu';
    if (deliveryState === 'sent-to-store') return 'wysłano do sklepu';
    if (deliveryState === 'received-by-store') return 'odebrano w sklepie';
    if (deliveryState === 'sent-to-stock') return 'wysłano do magazynu';
    if (deliveryState === 'received') return 'odebrano w magazynie';
  }

  const isExtendRental = transaction.prolongGroup.find(t => {
    const transactionEnd = moment(transaction.attributes.end);
    const prolongStart = moment(t.attributes.start);

    return t.attributes.state !== "declined" && moment(transactionEnd).isSame(prolongStart)
  });

  const isExtendRentalBtn = txRoleIsCustomer(ownRole)
    && canBeProlongedTo
    && canBeProlongedTo.length > 0
    && !isExtendRental;

  //cooperation f with Medicover Sport
  const medicoverPartnership = transaction.attributes.partnership;
  const isPartnershipWarning = transaction.attributes.partnership
    && transitions.length === 1
    && transitions[0].transition === "paymentless-accept";
  const partnershipWarningMessage = isProvider
    ? "TransactionPanel.salePartnershipWarning"
    : "TransactionPanel.orderPartnershipWarning";

  return (
    <div className={css.container}>
      {successDetails}
      <div className={css.navigation}>
        <NamedLink
          className={classNames(css.text, css.link)}
          name="InboxPage"
          params={{
            tab: isProvider || currentUser.attributes.isAdmin ? 'sales' : 'orders',
            providerId: currentUserProviderId || 'orders',
          }}
        >
          <FormattedMessage id="DetailOrder.booking" />
        </NamedLink>
        <IconArrowHead direction={'right'} className={css.arrow} />
        <span className={css.text}>
          <FormattedMessage id="DetailOrder.orderFrom" values={{ providerName }} />
        </span>
      </div>
      {isPartnershipWarning && <WarningComponent message={partnershipWarningMessage}/>}
      <div className={css.wrapper}>
        <OrderDetails
          providerName={providerName}
          listings={listings}
          attributes={transaction.attributes}
          intl={intl}
          actualOrderedProducts={expiredProducts ? expiredProducts : actualOrderedProducts}
          transaction={transaction}
          isOrder={txRoleIsCustomer(ownRole)}
          transactionStatus={transactionStatus}
        />
        <div className={css.actionButtons}>
          {medicoverPartnership &&
            <div className={classNames(css.medicoverContainer, {[css.orderMedicoverContainer]:!isProvider})}>
              <LogoMedicoverSport />
            </div>}
          <ActionButtons
            showButtons={showSaleButtons}
            acceptInProgress={acceptInProgress}
            declineInProgress={declineInProgress}
            pickupInProgress={pickupInProgress}
            cancelInProgress={cancelInProgress}
            completeNotPickedupInProgress={completeNotPickedupInProgress}
            completeInProgress={completeInProgress}
            acceptSaleError={acceptSaleError}
            declineSaleError={declineSaleError}
            pickupSaleError={pickupSaleError}
            completeSaleError={completeSaleError}
            status={status}
            onAcceptSale={() => onAcceptSale(transaction.id)}
            onDeclineSale={() => onDeclineSale(transaction.id)}
            onPickupSale={() => onPickupSale(transaction.id)}
            onCompleteNotPickedupSale={() => onCompleteNotPickedupSale(transaction.id)}
            onCompleteSale={() => onCompleteSale(transaction.id)}
            onCancelSale={() => onCancelSale(transaction.id)}
            transaction={transaction}
            isAdmin={isAdmin}
            changeDeliveryStateInProgress={changeDeliveryStateInProgress}
          />
          {txIsDelivered(transaction) && !review && txRoleIsCustomer(ownRole) && (
            <InlineTextButton onClick={openRateModal} className={css.button}>
              <FormattedMessage id={`OrderInfo.rate`} values={{ displayName }} />
            </InlineTextButton>
          )}
          {
            isExtendRentalBtn && (
              <Button onClick={openProlongationModal} className={css.extendRentalBtn}>
                {intl.formatMessage({ id: 'TransactionPanel.extendRental' })}
              </Button>
            )
          }
          {isProtocolButton && protocolFile && (
            <a className={css.protocolBtn} href={`${process.env.REACT_APP_CANONICAL_ROOT_URL}${protocolFile.url}`} target="_blank"><FormattedMessage id={'OrderInfo.downloadProtocol'} /></a>
          )}
          {isPartialCancelButton && (
            <Button className={css.partialCancelBtn} onClick={openPartialCancelModal}>
              <FormattedMessage id={'TransactionPanelNew.partialCancel'} />
            </Button>
          )}
          {transaction.attributes.deliveryType === 'store' && !txRoleIsCustomer(ownRole) &&
            <div className={css.centralRental}>
              <h4>Wypożyczenie z magazynu centralnego</h4>
              <h5>Status transportu: {computeDeliveryState(transaction.attributes.deliveryState)}</h5>
              <div>
                <SecondaryButton
                  inProgress={changeDeliveryStateInProgress}
                  className={classNames(css.actionButton, css.deliveryButton)}
                  onClick={openDeliveryStateModal}
                >
                  Zmień status dostawy
                </SecondaryButton>
              </div>
            </div>
          }
        </div>
      </div>
      <OrderInfo
        transaction={transaction}
        deliveryToProvider={transaction.deliveryToProvider}
        provider={transaction.provider}
        listings={transaction.listings}
        intl={intl}
        isAdminOrProvider={currentUser.attributes.isAdmin || isProvider}
      />
      <ReviewModal
        id="ReviewOrderModal"
        isOpen={isReviewModalOpen}
        onCloseModal={closeRateModal}
        onManageDisableScrolling={onManageDisableScrolling}
        onSubmitReview={onSubmitReview}
        revieweeName={displayName}
        reviewSent={reviewSubmitted}
        sendReviewInProgress={sendReviewInProgress}
        sendReviewError={sendReviewError}
      />
      <PartialCancelModal
        id="PartialCancelModal"
        isOpen={isPartialCancelModalOpen}
        onCloseModal={closePartialCancelModal}
        onManageDisableScrolling={onManageDisableScrolling}
        partialCancelInProgress={partialCancelInProgress}
        partialCancelError={partialCancelError}
        onPartialCancelOrder={onPartialCancelOrder}
        transaction={transaction}
        partialCancelSuccess={partialCancelSuccess}
        actualOrderedProducts={actualOrderedProducts}
      />
      <DeliveryStateModal
        id="DeliveryStateModal"
        onCloseModal={closeDeliveryStateModal}
        isOpen={isDeliveryStateModalOpen}
        onManageDisableScrolling={onManageDisableScrolling}
        onChangeDeliveryState={(deliveryState) => onChangeDeliveryState(transaction.id, deliveryState)}
        initialDeliveryState={transaction.attributes.deliveryState}
      />
      {
        txRoleIsCustomer(ownRole) && canBeProlongedTo && canBeProlongedTo.length > 0 &&
        <ProlongationModal
          id="ProlongationModal"
          onCloseModal={closeProlongationModalOpen}
          isOpen={isProlongationModalOpen}
          onManageDisableScrolling={onManageDisableScrolling}
          canBeProlongedTo={canBeProlongedTo}
          transactionId={transaction.id.uuid}
          callSetInitialValues={callSetInitialValues}
          onInitializeCardPaymentData={onInitializeCardPaymentData}
          listings={listings}
          provider={provider}
          transaction={transaction}
          actualOrderedProducts={actualOrderedProducts}
        />
      }
      <div className={css.messagesSection}>
        <SendMessageSection
          className={css.sendMessageContainer}
          transaction={transaction}
          sendMessageInProgress={sendMessageInProgress}
          sendMessageError={sendMessageError}
          onSendMessage={onSendMessage}
          intl={intl}
          currentUser={currentUser}
        />
        <FeedSection
          rootClassName={css.feedContainer}
          currentTransaction={transaction}
          currentUser={currentUser}
          fetchMessagesError={fetchMessagesError}
          fetchMessagesInProgress={fetchMessagesInProgress}
          initialMessageFailed={initialMessageFailed}
          messages={messages}
          review={review}
          oldestMessagePageFetched={oldestMessagePageFetched}
          onOpenReviewModal={openRateModal}
          onShowMoreMessages={() => onShowMoreMessages(transaction.id)}
          totalMessagePages={totalMessagePages}
          isProvider={isProvider}
          onUpdateTransaction={onUpdateTransaction}
        />
        {isProlongGroup
          ? <ProlongGroupSection
              transaction={transaction}
              intl={intl}
              transactionStatus={transactionStatus}
              history={history}
            />
          : null}
      </div>
    </div>
  );
};

TransactionPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  acceptSaleError: null,
  declineSaleError: null,
  fetchMessagesError: null,
  initialMessageFailed: false,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  sendReviewError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  nextTransitions: null,
};

TransactionPanelComponent.propTypes = {
  rootClassName: string,
  className: string,
  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction.isRequired,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailed: bool,
  savePaymentMethodFailed: bool,
  fetchMessagesInProgress: bool.isRequired,
  fetchMessagesError: propTypes.error,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  sendReviewInProgress: bool.isRequired,
  sendReviewError: propTypes.error,
  onManageDisableScrolling: func.isRequired,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  onSendReview: func.isRequired,
  onSubmitBookingRequest: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  nextTransitions: array,
  onAcceptSale: func.isRequired,
  onDeclineSale: func.isRequired,
  acceptInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
  acceptSaleError: propTypes.error,
  declineSaleError: propTypes.error,
  intl: intlShape,
};

const TransactionPanel = injectIntl(TransactionPanelComponent);

export default TransactionPanel;
