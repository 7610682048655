import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconWarning.css';

const DISABLED = 'disabled';
const WARNING = 'warning';
const ERROR = 'error';

const IconWarning = ({ rootClassName, className, variant = WARNING }) => {
  const classes = classNames(rootClassName || css.root, className);

  const warningIcon = (
    <svg
      className={classes}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.49389 21.25H19.9766C20.4796 21.25 20.9162 21.1372 21.2864 20.9117C21.6636 20.6931 21.9535 20.3992 22.1561 20.0302C22.3587 19.6612 22.46 19.2546 22.46 18.8104C22.46 18.5986 22.4321 18.3936 22.3762 18.1954C22.3273 17.9904 22.2469 17.7922 22.1352 17.6009L13.8886 3.24028C13.658 2.83027 13.3472 2.52276 12.956 2.31776C12.5648 2.10592 12.1596 2 11.7405 2C11.3213 2 10.9127 2.10592 10.5145 2.31776C10.1233 2.52276 9.81243 2.83027 9.5819 3.24028L1.32483 17.6214C1.10828 18.0041 1 18.4004 1 18.8104C1 19.2546 1.10129 19.6612 1.30388 20.0302C1.51345 20.3992 1.80335 20.6931 2.17359 20.9117C2.55082 21.1372 2.99092 21.25 3.49389 21.25ZM11.7405 14.4746C11.3004 14.4746 11.0768 14.2457 11.0699 13.7878L10.9546 8.19116C10.9546 7.97932 11.0244 7.80165 11.1642 7.65815C11.3109 7.51464 11.503 7.44289 11.7405 7.44289C11.964 7.44289 12.1491 7.51464 12.2958 7.65815C12.4495 7.80165 12.5264 7.98274 12.5264 8.20141L12.4006 13.7878C12.3936 14.2457 12.1736 14.4746 11.7405 14.4746ZM11.7405 17.7137C11.475 17.7137 11.241 17.6214 11.0384 17.4369C10.8428 17.2524 10.745 17.0303 10.745 16.7706C10.745 16.511 10.8428 16.2889 11.0384 16.1044C11.234 15.9199 11.468 15.8276 11.7405 15.8276C12.0129 15.8276 12.2469 15.9199 12.4425 16.1044C12.6451 16.2889 12.7464 16.511 12.7464 16.7706C12.7464 17.0303 12.6451 17.2524 12.4425 17.4369C12.2469 17.6214 12.0129 17.7137 11.7405 17.7137Z"
        fill="#F8AF2D"
      />
    </svg>
  );

  const disabledIcon = (
    <svg
      className={classes}
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.231 5C11.231 4.72386 11.0071 4.5 10.731 4.5C10.4548 4.5 10.231 4.72386 10.231 5V11C10.231 11.2761 10.4548 11.5 10.731 11.5C11.0071 11.5 11.231 11.2761 11.231 11V5Z"
        fill="#D9DDE1"
      />
      <path
        d="M10.731 13.75C10.1787 13.75 9.73097 14.1977 9.73097 14.75C9.73097 15.3023 10.1787 15.75 10.731 15.75C11.2833 15.75 11.731 15.3023 11.731 14.75C11.731 14.1977 11.2833 13.75 10.731 13.75Z"
        fill="#D9DDE1"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.896 1.25C11.9338 -0.416666 9.52816 -0.416667 8.56591 1.25L0.338666 15.5C-0.623584 17.1667 0.579228 19.25 2.50373 19.25H18.9582C20.8827 19.25 22.0855 17.1667 21.1233 15.5L12.896 1.25ZM9.43193 1.75C10.0093 0.75 11.4527 0.75 12.03 1.75L20.2573 16C20.8346 17 20.1129 18.25 18.9582 18.25H2.50373C1.34903 18.25 0.627341 17 1.20469 16L9.43193 1.75Z"
        fill="#D9DDE1"
      />
    </svg>
  );

  const errorIcon = (
    <svg
      className={classes}
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.231 5C11.231 4.72386 11.0071 4.5 10.731 4.5C10.4548 4.5 10.231 4.72386 10.231 5V11C10.231 11.2761 10.4548 11.5 10.731 11.5C11.0071 11.5 11.231 11.2761 11.231 11V5Z"
        fill="#ff0000"
      />
      <path
        d="M10.731 13.75C10.1787 13.75 9.73097 14.1977 9.73097 14.75C9.73097 15.3023 10.1787 15.75 10.731 15.75C11.2833 15.75 11.731 15.3023 11.731 14.75C11.731 14.1977 11.2833 13.75 10.731 13.75Z"
        fill="#ff0000"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.896 1.25C11.9338 -0.416666 9.52816 -0.416667 8.56591 1.25L0.338666 15.5C-0.623584 17.1667 0.579228 19.25 2.50373 19.25H18.9582C20.8827 19.25 22.0855 17.1667 21.1233 15.5L12.896 1.25ZM9.43193 1.75C10.0093 0.75 11.4527 0.75 12.03 1.75L20.2573 16C20.8346 17 20.1129 18.25 18.9582 18.25H2.50373C1.34903 18.25 0.627341 17 1.20469 16L9.43193 1.75Z"
        fill="#ff0000"
      />
    </svg>
  );

  switch (variant) {
    case WARNING:
      return warningIcon;
    case ERROR:
      return errorIcon;
    case DISABLED:
      return disabledIcon;
    default:
      return <></>;
  }
};

IconWarning.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconWarning.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconWarning;
