import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { parse } from '../../util/urlHelpers';
import config from '../../config';

// ================ Action types ================ //

export const FETCH_CONSOLE_REVIEWS_REQUEST = 'app/ConsoleReviewPage/FETCH_CONSOLE_REVIEWS_REQUEST';
export const FETCH_CONSOLE_REVIEWS_SUCCESS = 'app/ConsoleReviewPage/FETCH_CONSOLE_REVIEWS_SUCCESS';
export const FETCH_CONSOLE_REVIEWS_ERROR = 'app/ConsoleReviewPage/FETCH_CONSOLE_REVIEWS_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchInProgress: false,
  fetchReviewListError: null,
  pagination: null,
  reviewList: [],
};

export default function consoleReviewPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_CONSOLE_REVIEWS_REQUEST:
      return { ...state, fetchInProgress: true, fetchReviewListError: null };
    case FETCH_CONSOLE_REVIEWS_SUCCESS: {
      return {
        ...state,
        fetchInProgress: false,
        reviewList: payload.data.data,
        pagination: payload.data.meta,
      };
    }
    case FETCH_CONSOLE_REVIEWS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchProvidersError: payload };

    default:
      return state;
  }
}
// ================ Action creators ================ //

const fetchReviewListRequest = () => ({ type: FETCH_CONSOLE_REVIEWS_REQUEST });

const fetchReviewListSuccess = response => ({
  type: FETCH_CONSOLE_REVIEWS_SUCCESS,
  payload: response,
});

const fetchReviewListError = e => ({
  type: FETCH_CONSOLE_REVIEWS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const CONSOLE_PAGE_SIZE = 100;

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { page = 1, countryId = config.custom.countryId, rating, start, end, providerId } = parse(search);

  const currentEndDate = end ? new Date(end) : new Date();
  const currentEndDay = currentEndDate.getDate();
  const currentEndYear = currentEndDate.getFullYear();
  const currentEndMonth = currentEndDate.getMonth();
  const endDay = new Date(currentEndYear, currentEndMonth, currentEndDay + 2)
    .toISOString()
    .split('T')[0];

  const paginationParams = {
    page,
    per_page: CONSOLE_PAGE_SIZE,
  };

  const queryParams = {
    ...paginationParams,
    include: ['provider', 'transaction'],
    countryId,
    providerId,
    rating,
    createdAtFrom: start,
    createdAtTo: endDay
  };

  dispatch(fetchReviewListRequest());

  return sdk.newSdk.reviews
    .query(queryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchReviewListSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchReviewListError(e));
      throw e;
    });
};
