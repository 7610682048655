import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage  } from 'react-intl';
import { Modal, Button, InlineTextButton } from '../../../components';

import css from './AssignCatalogItemModal.css';
import CatalogProductSelectField from '../CatalogProductSelectField';
import CatalogItemSelectField from '../CatalogItemSelectField';

const AssignCatalogItemModal = props => {
  const {
    className,
    rootClassName,
    id,
    isOpen,
    onCloseModal,
    onManageDisableScrolling=false,
    inProgress,
    form,
    intl,
    productCatalogList,
    catalogProduct,
    handleSubmit
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  const handelClose = (event) => {
    event.preventDefault();
    form.change('catalogProduct', '');
    form.change('catalogItem', '');
    onCloseModal();
  }

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      closeButtonClassName={css.closeButton}
      isOpen={isOpen}
      onClose={handelClose}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
    >
      <div className={css.modalContent}>
        <div className={css.container}>
          <p className={css.modalTitle}>
            <FormattedMessage id="EditListingDescriptionForm.assignCatalogProduct" />
          </p>
          {isOpen ? <CatalogProductSelectField
            id="catalogProduct"
            name="catalogProduct"
            catalogProducts={productCatalogList}
            markRequired={true}
            intl={intl}
            form={form}
          /> : null}
          {isOpen ? <CatalogItemSelectField
            id="catalogItem"
            name="catalogItem"
            intl={intl}
            markRequired={true}
            catalogProducts={productCatalogList}
            catalogProduct={catalogProduct}
            form={form}
          /> : null}
        </div>
        <div className={css.buttonSection}>
          <InlineTextButton className={css.cancelButton} onClick={handelClose} type='button'>
            <FormattedMessage id="PartialCancelModal.cancel" />
          </InlineTextButton>
          <Button onClick={handleSubmit} className={css.confirmButton} inProgress={inProgress}>
            <FormattedMessage id="ConfirmModal.confirm" />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const { bool, string } = PropTypes;

AssignCatalogItemModal.defaultProps = {
  className: null,
  rootClassName: null,
  inProgress: false,
};

AssignCatalogItemModal.propTypes = {
  className: string,
  rootClassName: string,
};

export default AssignCatalogItemModal;
