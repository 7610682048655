import React from 'react';
import { NamedLink } from '../../components';
import config from '../../config';

import BikeElectric from './bike_electric.webp';
import BikeGravel from './bike_gravel.webp';
import CampingTent from './camping_tent.webp';
import Sup from './sup.webp';
import Trailer from './trailer.avif';
import BikeMountain from './bike_mountain.webp';
import CampingAccessories from './camping_accessories.webp';
import Carrier from './carrier.webp';
import Crampons from './crampons.webp';
import Winter from './camping.png';
import Fitness from './fitness.avif';
import Ski from './ski.avif';
import Snowboard from './snowboard.avif';
import CrossCountry from './inovik.jpg';
import BikeRoad from './roadbike.avif';
import Transport from './transport.avif';
import Campers from './camper.png';

import css from './LandingPage.css';

const SectionCategories = props => {
  const { intl } = props;

  const sportsData = [
    { img: BikeElectric, countries: ['pl', 'hu', 'cz', 'lt', 'ee', 'lv'], name: intl.formatMessage({ id: 'LandingPage.sportsList.bikeElectric' }), to: 'businessCategory=bikes_electric&category=bikes&exact=true' },
    { img: BikeGravel, countries: [], name: intl.formatMessage({ id: 'LandingPage.sportsList.bikeGravel' }), to: 'businessCategory=bikes_gravel&category=bikes&exact=true' },
    { img: BikeMountain, countries: [], name: intl.formatMessage({ id: 'LandingPage.sportsList.bikeMountain' }), to: 'businessCategory=bikes_mountain&category=bikes&exact=true' },
    { img: BikeRoad, countries: ['cz', 'pl'], name: intl.formatMessage({ id: 'LandingPage.sportsList.bikeRoad' }), to: 'businessCategory=bikes_road&category=bikes&exact=true' },
    { img: CampingTent, countries: [], name: intl.formatMessage({ id: 'LandingPage.sportsList.campingTent' }), to: 'businessCategory=camping_tent&category=camping&exact=true' },
    { img: CampingAccessories, countries: ['pl', 'cz', 'hu'], name: intl.formatMessage({ id: 'LandingPage.sportsList.campingAccessories' }), to: 'businessCategory=camping_accessories&category=camping&exact=true' },
    { img: Sup, countries: ['pl', 'hu', 'cz'], name: intl.formatMessage({ id: 'LandingPage.sportsList.sup' }), to: 'businessCategory=watersports_sup&category=watersports&exact=true' },
    { img: Carrier, countries: ['pl', 'hu'], name: intl.formatMessage({ id: 'LandingPage.sportsList.carrier' }), to: 'businessCategory=tourism_carrier&category=tourism&exact=true' },
    // { img: Crampons, countries: ['pl', 'cz', 'hu'], name: intl.formatMessage({ id: 'LandingPage.sportsList.crampons' }), to: 'businessCategory=tourism_crampons&category=tourism&exact=true' },
    { img: Trailer, countries: ['pl'], name: intl.formatMessage({ id: 'LandingPage.sportsList.trailer' }), to: 'businessCategory=bikes_trailer&category=bikes&exact=true' },
    { img: Fitness, countries: ['cz'], name: intl.formatMessage({ id: 'LandingPage.sportsList.fitness' }), to: 'businessCategory=fitness_cardio&category=fitness&exact=true' },
    { img: Transport, countries: ['pl'], name: intl.formatMessage({ id: 'LandingPage.sportsList.transport' }), to: 'category=transport' },
    { img: Campers, countries: ['pl'], name: intl.formatMessage({ id: 'LandingPage.sportsList.camper' }), to: 'businessCategory=camping_campers&exact=true&category=camping' },
    // { img: CrossCountry, countries: ['cz'], name: intl.formatMessage({ id: 'LandingPage.sportsList.crossCountry' }), to: 'businessCategory=wintersports_crosscountry&category=wintersports&exact=true' },
    // { img: Ski, countries: ['hu', 'pl'], name: intl.formatMessage({ id: 'LandingPage.sportsList.ski' }), to: 'businessCategory=wintersports_alpineskiing&category=wintersports&exact=true' },
    // { img: Snowboard, countries: ['cz', 'hu'], name: intl.formatMessage({ id: 'LandingPage.sportsList.snowboard' }), to: 'businessCategory=wintersports_snowboard&category=wintersports&exact=true' },
  ];

  return (
    <div className={css.sportsList}>
      <ul>
        {sportsData.filter(cat => {
          return cat.countries.length === 0 || cat.countries.includes(config.custom.countryId);
        }).map(({ img, name, to }, key) => (
          <li key={key}>
            <NamedLink
              name="SearchPage"
              to={{
                search: to,
              }}
            >
              <img src={img} alt={name} />
              <h2>{name}</h2>
            </NamedLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SectionCategories;
