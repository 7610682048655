import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';

// ================ Action types ================ //

export const ADD_TO_CART_REQUEST = 'app/CartPage/ADD_TO_CART_REQUEST';
export const ADD_TO_CART_SUCCESS = 'app/CartPage/ADD_TO_CART_SUCCESS';
export const ADD_TO_CART_ERROR = 'app/CartPage/ADD_TO_CART_ERROR';

export const FETCH_CART_REQUEST = 'app/CartPage/FETCH_CART_REQUEST';
export const FETCH_CART_SUCCESS = 'app/CartPage/FETCH_CART_SUCCESS';
export const FETCH_CART_ERROR = 'app/CartPage/FETCH_CART_ERROR';

export const DELETE_FROM_CART_REQUEST = 'app/CartPage/DELETE_FROM_CART_REQUEST';
export const DELETE_FROM_CART_SUCCESS = 'app/CartPage/DELETE_FROM_CART_SUCCESS';
export const DELETE_FROM_CART_ERROR = 'app/CartPage/DELETE_FROM_CART_ERROR';

// ================ Reducer ================ //

const initialState = {
  addToCartInProgress: false,
  addToCartError: null,
  addToCartSuccess: false,
  fetchInProgress: false,
  fetchCartError: null,
  deleteFromCartInProgress: false,
  deleteFromCartError: null,
  deleteFromCartSuccess: false,
  pagination: null,
  cart: [],
};

export default function cartPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case ADD_TO_CART_REQUEST:
      return { ...state, addToCartInProgress: true, addToCartError: null, addToCartSuccess: false };
    case ADD_TO_CART_SUCCESS:
      return { ...state, addToCartInProgress: false,  addToCartError: null, addToCartSuccess: true };
    case ADD_TO_CART_ERROR:
      return { ...state, addToCartInProgress: false, addToCartError: payload, addToCartSuccess: false };
    case FETCH_CART_REQUEST:
      return { ...state, fetchInProgress: true, fetchCartError: null };
    case FETCH_CART_SUCCESS: {
      return {
        ...state,
        fetchInProgress: false,
        cart: payload.data.data,
        pagination: payload.data.meta,
      };
    }
    case FETCH_CART_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchCartError: payload };
    case DELETE_FROM_CART_REQUEST:
      return {
        ...state,
        deleteFromCartInProgress: true,
        deleteFromCartError: null,
        deleteFromCartSuccess: false,
      };
    case DELETE_FROM_CART_SUCCESS:
      return { ...state, deleteFromCartInProgress: false, deleteFromCartSuccess: true };
    case DELETE_FROM_CART_ERROR:
      return {
        ...state,
        deleteFromCartInProgress: false,
        deleteFromCartError: payload,
        deleteFromCartSuccess: false,
      };
    default:
      return state;
  }
}
// ================ Action creators ================ //

const initiateAddToCartRequest = () => ({ type: ADD_TO_CART_REQUEST });

const addToCartSuccess = response => ({
  type: ADD_TO_CART_SUCCESS,
  payload: response,
});

const addToCartError = (e) => ({ 
  type: ADD_TO_CART_ERROR,  
  error: true,
  payload: e,
});

const fetchCartRequest = () => ({ type: FETCH_CART_REQUEST });

const fetchCartSuccess = response => ({
  type: FETCH_CART_SUCCESS,
  payload: response,
});

const fetchCartError = e => ({
  type: FETCH_CART_ERROR,
  error: true,
  payload: e,
});

const deleteCartRequest = () => ({ type: DELETE_FROM_CART_REQUEST });

const deleteFromCartError = e => ({
  type: DELETE_FROM_CART_ERROR,
  error: true,
  payload: e,
});

const deleteFromCartSuccess = response => ({
  type: DELETE_FROM_CART_SUCCESS,
  payload: response,
});

// ================ Thunks ================ //

export const addToCart = cartParams => (dispatch, getState, sdk) => {
  dispatch(initiateAddToCartRequest());

  return sdk.newSdk.cartListings
    .create({
      listingId: cartParams.listingId,
      amount: cartParams.amount,
      start: cartParams.start,
      end: cartParams.end,
    })
    .then(response => {
      dispatch(addToCartSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(addToCartError(e));
      throw e;
    });
};

export const deleteFromCart = listingId => (dispatch, getState, sdk) => {
  dispatch(deleteCartRequest());

  return sdk.newSdk.cartListings
    .delete({ id: listingId })
    .then(response => {
      dispatch(deleteFromCartSuccess(response));
      return response;
    })
    .catch(error => {
      dispatch(deleteFromCartError(error));
      throw error;
    });
};

export const addCartToUser = (cartId, email) => (dispatch, getState, sdk) => {
  return sdk.newSdk.cartPreorders
    .update({ id: cartId, clientEmail: email })
    .then(response => {
      return response;
    })
    .catch(e => {
      throw e;
    });
}

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const queryParams = {
    include: ['cartListings', 'cartListings.listing', 'provider', 'cartListings.listing.images', 'provider.deliveryToProviders'],
  };

  dispatch(fetchCartRequest());

  if (typeof window === 'undefined') {
    return Promise.resolve({
      data: {
        data: [],
        included: [],
        meta: {}
      }
    })
      .then(response => {
        dispatch(addMarketplaceEntities(response));
        dispatch(fetchCartSuccess(response));
        return response;
      })
  }

  return sdk.newSdk.cartPreorders
    .query(queryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchCartSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchCartError(e));
      throw e;
    });
};
