import React from 'react';

import { required } from '../../util/validators';
import { FieldSelect } from '../../components';

import css from './EditListingDescriptionForm.css';

const CatalogItemSelectField = props => {
  const {
    name,
    id,
    intl,
    catalogProducts,
    catalogProduct,
    markRequired,
    form,
    disabled
  } = props;
  const product = catalogProducts.find(item => item.id.uuid === catalogProduct);
  const catalogItems = product && product.catalogItems;

  const catalogItemLabel = intl.formatMessage({
    id: 'EditListingDescriptionForm.catalogItemLabel',
  });
  const catalogItemPlaceholder = intl.formatMessage({
    id: 'EditListingDescriptionForm.catalogItemPlaceholder',
  });
  const catalogItemRequired = required(
    intl.formatMessage({
      id: 'EditListingDescriptionForm.catalogItemRequired',
    })
  );

  const handelChange = (event) => {
    form.change('catalogItem', event.target.value);
  }

  return (
    <FieldSelect
      className={catalogProduct ? css.category : css.categoryDisabled}
      name={name}
      id={id}
      label={catalogItemLabel}
      disabled={!catalogProduct || disabled}
      markRequired={markRequired}
      onChange={handelChange}
      validate={catalogItemRequired}
    >
      <option disabled value="">
        {catalogItemPlaceholder}
      </option>
      {catalogItems?.length && catalogItems.map(item => (
          <option key={item.id.uuid} value={item.id.uuid} >
            {item.attributes.articleCode} - {item.attributes.title}
          </option>
        ))}
    </FieldSelect>
  );
};

export default CatalogItemSelectField;
