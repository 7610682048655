import React from 'react';
import classNames from 'classnames';
import moment from 'moment';

import { FormattedMessage } from '../../../util/reactIntl';
import { PrimaryButton, SecondaryButton } from '../../../components';

import css from './ActionButtons.css';

const ActionButtons = props => {
  const {
    className,
    rootClassName,
    showButtons,
    acceptInProgress,
    declineInProgress,
    cancelInProgress,
    pickupInProgress,
    completeInProgress,
    completeNotPickedupInProgress,
    acceptSaleError,
    declineSaleError,
    onAcceptSale,
    onDeclineSale,
    onPickupSale,
    onCompleteNotPickedupSale,
    onCompleteSale,
    onCancelSale,
    status,
    transaction,
    isAdmin,
    onChangeDeliveryState,
    changeDeliveryStateInProgress,
  } = props;

  const buttonsDisabled = acceptInProgress || declineInProgress || cancelInProgress;

  const acceptErrorMessage = acceptSaleError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.acceptSaleFailed" />
    </p>
  ) : null;
  const declineErrorMessage = declineSaleError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.declineSaleFailed" />
    </p>
  ) : null;

  const classes = classNames(rootClassName || css.actionButtons, className);

  const disabledCompleteNotPickedupButton = moment().add(1, 'days').isBefore(transaction.attributes.end, 'days');

  return showButtons ? (
    <div className={classes}>
      <div className={css.actionErrors}>
        {acceptErrorMessage}
        {declineErrorMessage}
      </div>
      <div className={css.actionButtonWrapper}>
        {
          status === 'requested' &&
          <>
            <SecondaryButton
              className={css.actionButton}
              inProgress={declineInProgress}
              disabled={buttonsDisabled}
              onClick={onDeclineSale}
              confirmationNeeded
            >
              <FormattedMessage id="TransactionPanel.declineButton" />
            </SecondaryButton>
            <PrimaryButton
              className={css.actionButton}
              inProgress={acceptInProgress}
              disabled={buttonsDisabled}
              onClick={onAcceptSale}
              confirmationNeeded
            >
              <FormattedMessage id="TransactionPanel.acceptButton" />
            </PrimaryButton>
          </>
        }
        {
          status === 'accepted' &&
          <>
            <PrimaryButton
              className={classNames(css.actionButton, css.greenBtn)}
              inProgress={pickupInProgress}
              disabled={buttonsDisabled}
              onClick={onPickupSale}
              confirmationNeeded
            >
              <FormattedMessage id="TransactionPanel.pickupButton" />
            </PrimaryButton>
            <SecondaryButton
              className={css.actionButton}
              inProgress={cancelInProgress}
              disabled={buttonsDisabled}
              onClick={onCancelSale}
              confirmationNeeded
            >
              <FormattedMessage id="TransactionPanel.cancelButton" />
            </SecondaryButton>

            <SecondaryButton
              className={css.actionButton}
              inProgress={completeNotPickedupInProgress}
              disabled={buttonsDisabled || disabledCompleteNotPickedupButton}
              onClick={onCompleteNotPickedupSale}
              confirmationNeeded
            >
              <FormattedMessage id="TransactionPanel.completeNotPickedupButton" />
            </SecondaryButton>
          </>
        }
        {
          status === 'picked-up' &&
          <>
            <PrimaryButton
              className={classNames(css.actionButton, css.greenBtn)}
              inProgress={completeInProgress}
              disabled={buttonsDisabled}
              onClick={onCompleteSale}
              confirmationNeeded
            >
              <FormattedMessage id="TransactionPanel.completeButton" />
            </PrimaryButton>
            {
              isAdmin &&
              <SecondaryButton
                className={classNames(css.actionButton, css.cancelPickedUp)}
                inProgress={cancelInProgress}
                disabled={buttonsDisabled}
                onClick={onCancelSale}
                confirmationNeeded
              >
                <FormattedMessage id="TransactionPanel.cancelButton" />
              </SecondaryButton>
            }
          </>
        }
      </div>
    </div>
  ) : null;
};

export default ActionButtons;
