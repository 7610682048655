import React from 'react';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';
import { formatMoney } from '../../../util/currency';
import {
  LINE_ITEM_PROVIDER_COMMISSION,
  LINE_ITEM_DAY,
  LINE_ITEM_LONG_TERM_DISCOUNT,
  LINE_ITEM_PROMO_DISCOUNT,
} from '../../../util/types';
import {
  TRANSITION_CANCEL_BY_OPERATOR,
  TRANSITION_CANCEL_BY_OPERATOR_AFTER_PICKUP,
  TRANSITION_CANCEL_BY_PROVIDER,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_CONFIRM_PAYMENT_INSTANT_BOOKING,
  TRANSITION_CONFIRM_PAYMENT_INSTANT_BOOKING_BY_OPERATOR,
} from '../../../util/transaction';

import { AddressLinkMaybe } from '../../../components';

import css from './OrderInfo.css';

const OrderInfo = ({ transaction, deliveryToProvider, provider, listings, intl, isAdminOrProvider }) => {
  const DELIVERED_TYPE_HOME = 'home';
  const CANCEL_TRANSITION = 'cancel';
  const PARTIAL_CANCEL_TRANSITION = 'partial-cancel';
  const CONFIRM_PAYMENT = 'confirm-payment';
  const PAYMENT_EXPIRED = "payment-expired";

  const {
    receiverName,
    firstName,
    lastName,
    phone,
    email,
    deliveryType,
    deliveryToHome,
    payinTotal,
    lineItems,
    documents,
    transitions,
    meta: { invoice, customerType },
    processState
  } = transaction.attributes;

  const showProducts = products => {
    const sortedProducts = products[0].reversal
      ? products.sort((a, b) => a.unitPrice.amount - b.unitPrice.amount)
      : products.sort((a, b) => b.unitPrice.amount - a.unitPrice.amount);

    return (
      <div>
        {sortedProducts.map(({ listingId, unitPrice, quantity, seats }) => {
          const listingItem = listings.find(({ id: { uuid } }) => listingId === uuid);
          const title = listingItem.attributes.title;
          return (
            <p className={classNames(css.text, css.priceContainer)} key={listingId}>
              <span className={css.productName}>{title}</span>
              <span className={css.text}>
                {Math.abs(seats)} x {formatMoney(intl, unitPrice)} x {quantity}{' '}
                <FormattedMessage id={quantity === 1 ? 'OrderInfo.day' : 'OrderInfo.days'} />
              </span>
            </p>
          );
        })}
      </div>
    );
  };

  const showDiscount = discount => {
    if (!discount || !discount.lineTotal.amount) return;
    const { lineTotal, code } = discount;

    return (
      <p className={classNames(css.text, css.priceContainer)} key={code}>
        <span className={css.productName}>
          <FormattedMessage id="OrderInfo.discountText" />
        </span>
        <span className={css.text}>{formatMoney(intl, lineTotal)}</span>
      </p>
    );
  };

  const showPromoDiscount = discount => {
    if (!discount || !discount.lineTotal.amount) return;
    const { lineTotal, code, percentage, reversal } = discount;

    return (
      <p
        className={classNames(css.text, css.priceContainer)}
        key={reversal ? `${code}_1` : `${code}_0`}
      >
        <span className={css.productName}>
          <FormattedMessage id="OrderInfo.discount" />
          {percentage && <span> {percentage}%</span>}
        </span>
        <span className={css.text}>{formatMoney(intl, lineTotal)}</span>
      </p>
    );
  };

  const showOriginOrder = confirmLineItems => {
    const originProducts = confirmLineItems.filter(item => item?.code === LINE_ITEM_DAY);
    const originDiscount = confirmLineItems.find(
      item => item?.code === LINE_ITEM_LONG_TERM_DISCOUNT
    );
    const originPromoDiscount = confirmLineItems.find(
      item => item?.code === LINE_ITEM_PROMO_DISCOUNT
    );

    const sum = originProducts.reduce(
      (sum, currentValue) => sum + currentValue.lineTotal.amount,
      0
    );
    const currency = originProducts[0].lineTotal.currency;
    const subTotalWithDiscount = originDiscount ? sum + originDiscount.lineTotal.amount : sum;
    const amountSubTotal = originPromoDiscount
      ? subTotalWithDiscount + originPromoDiscount.lineTotal.amount
      : subTotalWithDiscount;
    const subTotal = { amount: amountSubTotal, currency };

    return (
      <div key={'confirm'}>
        {showProducts(originProducts)}
        {showDiscount(originDiscount)}
        {showPromoDiscount(originPromoDiscount)}
        <p className={classNames(css.subtitle, css.borderBottom, css.priceContainer)}>
          <FormattedMessage id={'OrderInfo.subTotal'} /> {formatMoney(intl, subTotal)}
        </p>
      </div>
    );
  };

  const showPartCancel = (partialCancelLineItems, relLineItemIndex) => {
    const partialCancelProductList = partialCancelLineItems.filter(
      item => item?.code === LINE_ITEM_DAY
    );
    const longTermDiscountList = partialCancelLineItems.filter(
      item => item?.code === LINE_ITEM_LONG_TERM_DISCOUNT
    );
    const promoDiscountList = partialCancelLineItems.filter(
      item => item?.code === LINE_ITEM_PROMO_DISCOUNT
    );

    const productsId = [];

    partialCancelProductList.forEach(
      ({ listingId }) => !productsId.includes(listingId) && productsId.push(listingId)
    );

    const partialCancelProducts = productsId.map(id => {
      const productData = partialCancelProductList.filter(({ listingId }) => id === listingId);
      const seatDifference =
        productData.length > 1
          ? productData[1].seats - productData[0].seats
          : -productData[0].seats;
      const amountDifference =
        productData.length > 1
          ? productData[1].lineTotal.amount + productData[0].lineTotal.amount
          : productData[0].lineTotal.amount;
      const lineTotalDifference = {
        amount: amountDifference,
        currency: productData[0].lineTotal.currency,
      };

      return {
        ...productData[0],
        seats: seatDifference,
        lineTotal: lineTotalDifference,
      };
    });

    const currency = partialCancelProducts[0].lineTotal.currency;
    const discountReturned = longTermDiscountList.length
      ? {
        ...longTermDiscountList[0],
        unitPrice: {
          amount:
            longTermDiscountList[1].unitPrice.amount + longTermDiscountList[0].unitPrice.amount,
          currency: longTermDiscountList[0].unitPrice.currency,
        },
        lineTotal: {
          amount:
            longTermDiscountList[1].lineTotal.amount + longTermDiscountList[0].lineTotal.amount,
          currency: longTermDiscountList[0].lineTotal.currency,
        },
      }
      : null;

    const promoDiscountReturned = promoDiscountList.length
      ? {
        ...promoDiscountList[0],
        unitPrice: {
          amount: promoDiscountList[1].unitPrice.amount + promoDiscountList[0].unitPrice.amount,
          currency: promoDiscountList[0].unitPrice.currency,
        },
        lineTotal: {
          amount: promoDiscountList[1].lineTotal.amount + promoDiscountList[0].lineTotal.amount,
          currency: promoDiscountList[0].lineTotal.currency,
        },
      }
      : null;

    const sum = [...partialCancelProducts, discountReturned, promoDiscountReturned].reduce(
      (accumulator, currentValue) =>
        currentValue ? accumulator + currentValue?.lineTotal?.amount : accumulator,
      0
    );

    const refund = { amount: sum, currency };

    return (
      <div key={`partial_cancel${relLineItemIndex}`}>
        <p className={css.subtitle}>
          <FormattedMessage id={'OrderInfo.canceled'} />
        </p>
        {showProducts(partialCancelProducts)}
        {showDiscount(discountReturned)}
        {showPromoDiscount(promoDiscountReturned)}
        <p className={classNames(css.subtitle, css.priceContainer, css.borderBottom)}>
          <FormattedMessage id={'OrderInfo.refund'} /> {formatMoney(intl, refund)}
        </p>
      </div>
    );
  };

  const showCancel = cancelList => {
    const products = cancelList.filter(item => item?.code === LINE_ITEM_DAY);
    const updateProducts = products.map(item => {
      return {
        ...item,
        seats: -item.seats,
      };
    });

    const discount = cancelList.find(item => item?.code === LINE_ITEM_LONG_TERM_DISCOUNT);
    const promoDiscount = cancelList.find(item => item?.code === LINE_ITEM_PROMO_DISCOUNT);
    const sum = products.reduce((sum, currentValue) => sum + currentValue.lineTotal.amount, 0);
    const currency = products[0].lineTotal.currency;
    const subTotalWithDiscount = discount ? sum + discount.lineTotal.amount : sum;
    const amountSubTotal = promoDiscount
      ? subTotalWithDiscount + promoDiscount.lineTotal.amount
      : subTotalWithDiscount;
    const subTotal = { amount: amountSubTotal, currency };

    return (
      <div key={`cancel${amountSubTotal}`}>
        <p className={css.subtitle}>
          <FormattedMessage id={'OrderInfo.canceled'} />
        </p>
        {showProducts(updateProducts)}
        {showDiscount(discount)}
        {showPromoDiscount(promoDiscount)}
        <p className={classNames(css.subtitle, css.borderBottom, css.priceContainer)}>
          <FormattedMessage id={'OrderInfo.refund'} /> {formatMoney(intl, subTotal)}
        </p>
      </div>
    );
  };

  const confirmPaymentStatus = [
    TRANSITION_CONFIRM_PAYMENT,
    CONFIRM_PAYMENT,
    TRANSITION_CONFIRM_PAYMENT_INSTANT_BOOKING,
    TRANSITION_CONFIRM_PAYMENT_INSTANT_BOOKING_BY_OPERATOR,
  ];

  const cancelStatus = [
    CANCEL_TRANSITION,
    TRANSITION_CANCEL_BY_OPERATOR,
    TRANSITION_CANCEL_BY_OPERATOR_AFTER_PICKUP,
    TRANSITION_CANCEL_BY_PROVIDER,
  ];

  const showSummary = () =>
    transitions.map(({ transition, relLineItemIndex, relLineItemsAmount }, index) => {

      if (confirmPaymentStatus.includes(transition)) {
        const confirmList = lineItems.slice(
          relLineItemIndex,
          relLineItemIndex + relLineItemsAmount
        );

        return showOriginOrder(confirmList);
      }

      if (transition === PARTIAL_CANCEL_TRANSITION) {
        const partialCancelLineItems = lineItems.slice(
          relLineItemIndex,
          relLineItemIndex + relLineItemsAmount
        );

        return showPartCancel(partialCancelLineItems, relLineItemIndex);
      }

      if (cancelStatus.includes(transition)) {
        const cancelList = lineItems.slice(relLineItemIndex, relLineItemIndex + relLineItemsAmount);

        return showCancel(cancelList);
      }

      return null;
    });

  const commission = lineItems.filter(item => item?.code === LINE_ITEM_PROVIDER_COMMISSION);
  const commissionSum = commission.reduce(
    (accumulator, currentValue) => accumulator + currentValue?.lineTotal?.amount,
    0
  );

  const zeroLineTotal = {
    amount: 0,
    currency: payinTotal.currency,
  }

  const isPaymentExpired = processState === PAYMENT_EXPIRED;
  const totalCost = isPaymentExpired ? zeroLineTotal : payinTotal;

  const commissionLineTotal = isPaymentExpired ? zeroLineTotal : {
    amount: commission.length ? commissionSum : 0,
    currency: totalCost.currency,
  };

  const profit = isPaymentExpired ? zeroLineTotal : {
    amount: commissionLineTotal.amount + totalCost.amount,
    currency: totalCost.currency,
  };

  const addressDeliveryHome = deliveryToHome ? (
    <div className={classNames(css.section, css.sectionDeliverAddress)} key={'delivery_to_home'}>
      <p className={css.title}>
        <FormattedMessage id={'OrderInfo.deliveryAddress'} />
      </p>
      <p className={css.text}>{deliveryToHome.address}</p>
      <p className={css.text}>{deliveryToHome.city}</p>
    </div>
  ) : null;

  const { name, openingHours, businessLocation } = deliveryToProvider
    ? deliveryToProvider?.attributes
    : provider.attributes;

  const pickupAddress =
    deliveryType !== DELIVERED_TYPE_HOME ? (
      <div className={css.sectionDeliverAddress} key={'pickup_address'}>
        <p className={css.title}>
          <FormattedMessage id={'OrderInfo.pickupAddress'} />
        </p>
        <p className={css.subtitle}>{name}</p>
        {businessLocation && (
          <AddressLinkMaybe
            className={css.text}
            location={businessLocation.selectedPlace}
            geolocation={businessLocation.selectedPlace.origin}
            showAddress
          />
        )}
        {openingHours ? (
          <>
            <p className={classNames(css.subtitle, css.subtitleContainer)}>
              <FormattedMessage id={'OrderInfo.equipment'} />
            </p>
            <p className={classNames(css.text, css.openingHours)}>{openingHours}</p>
          </>
        ) : null}
      </div>
    ) : null;

  const getInvoice = invoiceClassName =>
    documents.filter(d => d.type === 'invoice' || d.type === 'invoice-correction').length > 0 ? (
      <div key={'invoice'}>
        {documents.filter(d => d.type === 'invoice').map((document, index) => (
          <a
            className={invoiceClassName}
            key={index}
            href={`${process.env.REACT_APP_CANONICAL_ROOT_URL}${document.url}`}
            target="_blank"
          >
            <FormattedMessage id={`OrderInfo.doc-invoice`} />
          </a>
        ))}
        {documents.filter(d => d.type === 'invoice-correction').map((document, index) => (
          <a
            className={invoiceClassName}
            key={index}
            href={`${process.env.REACT_APP_CANONICAL_ROOT_URL}${document.url}`}
            target="_blank"
          >
            <FormattedMessage id={`OrderInfo.doc-invoice-correction`} />
          </a>
        ))}
      </div>
    ) : null;

  const showInvoiceDetails = () => {
    if (!invoice) return;
    const isCompany = customerType === 'company';
    const { address, city, country, name, zip, companyName, taxId } = invoice;

    return (
      <>
        <p className={classNames(css.subtitle, css.subtitleContainer)}>
          <FormattedMessage id={'OrderInfo.billing'} />
        </p>
        <p className={css.text}>{isCompany ? companyName : name}</p>
        {isCompany && (
          <p className={css.text}>
            <FormattedMessage id={'OrderInfo.taxId'} />: {taxId}
          </p>
        )}
        <p className={css.text}>{address}</p>
        <p className={css.text}>
          {zip} {city}
        </p>
        <p className={css.text}>{country}</p>
      </>
    );
  };

  return (
    <div className={css.container}>
      <div className={classNames(css.section, css.sectionContactDetails)}>
        <p className={css.title}>
          <FormattedMessage id={'OrderInfo.contactDetails'} />
        </p>
        <p className={css.subtitle}>
          {firstName} {lastName}
        </p>
        <p className={css.text}>{email}</p>
        <p className={css.text}>{phone}</p>
        <p className={classNames(css.subtitle, css.subtitleContainer)}>
          <FormattedMessage id={'OrderInfo.receiver'} />
        </p>
        <p className={css.text}>{receiverName}</p>
        {showInvoiceDetails()}
      </div>
      {addressDeliveryHome}
      {pickupAddress}
      <div className={classNames(css.section, css.sectionPayment)}>
        <div className={classNames(css.titleContainer, css.title)}>
          <FormattedMessage id={'OrderInfo.paymentSummary'} />
        </div>
        {showSummary()}
        <p className={classNames(css.subtitle, css.priceContainer)}>
          <FormattedMessage id={'OrderInfo.totalCost'} /> {formatMoney(intl, totalCost)}
        </p>
        {commissionLineTotal && isAdminOrProvider && (
          <>
            <p className={classNames(css.text, css.priceContainer)}>
              <FormattedMessage id={'OrderInfo.commission'} />{' '}
              {formatMoney(intl, commissionLineTotal)}
            </p>
            <p className={classNames(css.text, css.priceContainer)}>
              <FormattedMessage id={'OrderInfo.profit'} /> {formatMoney(intl, profit)}
            </p>
          </>
        )}
        {getInvoice(classNames(css.text, css.invoice))}
      </div>
    </div>
  );
};

export default OrderInfo;
