import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bool } from 'prop-types';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';

import routeConfiguration from '../../routeConfiguration';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { deleteFromCart, loadData, addCartToUser } from './CartPage.duck';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  IconSpinner,
  Order,
} from '../../components';
import Summary from './Summary/Summary';
import { TopbarContainer } from '..';

import css from './CartPage.css';

const CartPageComponent = ({
  intl,
  cart,
  fetchInProgress,
  fetchCartError,
  deleteFromCart,
  deleteFromCartSuccess,
  fetchCart,
  history,
  callSetInitialValues,
  onInitializeCardPaymentData,
  onAddCartToUser,
}) => {
  const user = useSelector(state => state?.user?.currentUser);

  const errorMessage = fetchCartError ? (
    <p className={css.error}>{fetchCartError.message}</p>
  ) : null;

  const noResultsMessage =
    !fetchInProgress && !cart.length && !fetchCartError ? (
      <p className={css.noResultText}>
        <FormattedMessage id="Order.noOrders" />
      </p>
    ) : null;

  useEffect(() => {
    deleteFromCartSuccess && fetchCart();
  }, [deleteFromCartSuccess]);

    const handleSubmit = (values) => {
      const { bookingDates, ...bookingData } = values;

      const initialValues = {
        bookingDates: {
          bookingStart: values.start,
          bookingEnd: values.end,
        },
        confirmPaymentError: null,
        listings: values.cartListings,
        provider: values.provider,
        totalNumberOfOrders: values.totalNumberOfOrders,
      };

      const routes = routeConfiguration();
      // Customize checkout page state with current listing and selected bookingDates
      const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);
      callSetInitialValues(setInitialValues, initialValues);

      // Clear previous Stripe errors from store if there is any
      onInitializeCardPaymentData();

      // Redirect to CheckoutPage
      history.push(
        createResourceLocatorString(
          'CheckoutPage',
          routes,
          { cartId: values.cart },
          {}
        )
      );
    }

  const isAddToUserAvailable = !!user?.attributes?.hasProvider;

  return (
    <Page
      className={css.root}
      title={intl.formatMessage({ id: 'CartPage.title' })}
      scrollingDisabled={false}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="CartPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.main}>
          <div className={css.cartContainer}>
            {errorMessage}
            {noResultsMessage}
            {fetchInProgress ? (
              <div className={css.listItemsLoading}>
                <IconSpinner />
              </div>
            ) : (
              cart.map(({ cartListings, attributes, provider, id }, index) =>
                (
                  <Order
                    key={id.uuid}
                    orders={cartListings}
                    orderNumber={index + 1}
                    totalNumberOfOrders={cart.length}
                    intl={intl}
                    provider={provider}
                    deleteFromCart={deleteFromCart}
                    productCanBeRemoved={true}
                    className={css.orderContainer}
                  >
                    <Summary
                      start={attributes.start}
                      end={attributes.end}
                      payinTotal={attributes.payinTotal}
                      lineItems={attributes.lineItems}
                      cartListings={cartListings}
                      intl={intl}
                      totalNumberOfOrders={cart.length}
                      handleSubmit={handleSubmit}
                      provider={provider}
                      id={id.uuid}
                      onAddCartToUser={onAddCartToUser}
                      isAddToUserAvailable={isAddToUserAvailable}
                    />
                  </Order>
                )
              )
            )}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

CartPageComponent.defaultProps = {
  cart: [],
  fetchInProgress: false,
  fetchCartError: null,
  scrollingDisabled: false,
};

CartPageComponent.propTypes = {
  fetchInProgress: bool.isRequired,
  fetchCartError: propTypes.error,
  scrollingDisabled: bool.isRequired,
  // cart: arrayOf(propTypes.cartListing),
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    fetchInProgress,
    fetchCartError,
    cart,
    deleteFromCartInProgress,
    deleteFromCartSuccess,
  } = state.CartPage;

  return {
    fetchInProgress,
    fetchCartError,
    scrollingDisabled: isScrollingDisabled(state),
    cart: getMarketplaceEntities(state, cart),
    deleteFromCartInProgress,
    deleteFromCartSuccess,
  };
};

const mapDispatchToProps = dispatch => ({
  deleteFromCart: listingId => dispatch(deleteFromCart(listingId)),
  fetchCart: () => dispatch(loadData()),
  callSetInitialValues: (setInitialValues, values) => dispatch(setInitialValues(values)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onAddCartToUser: (cartId, email) => dispatch(addCartToUser(cartId, email)),
});

const CartPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withRouter
)(CartPageComponent);

CartPage.loadData = loadData;

export default CartPage;
