import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconDelete.css';

const IconDelete = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.02405 1.43518C5.02405 1.15904 5.2479 0.935181 5.52405 0.935181H12.276C12.5522 0.935181 12.776 1.15904 12.776 1.43518V4.00018H16.998C17.2742 4.00018 17.498 4.22404 17.498 4.50018C17.498 4.77632 17.2742 5.00018 16.998 5.00018H15.498V18.5002C15.498 18.7763 15.2742 19.0002 14.998 19.0002H2.99805C2.7219 19.0002 2.49805 18.7763 2.49805 18.5002V5.00018H0.998047C0.721904 5.00018 0.498047 4.77632 0.498047 4.50018C0.498047 4.22404 0.721904 4.00018 0.998047 4.00018H5.02405V1.43518ZM3.49805 5.00018V18.0002H14.498V5.00018H3.49805ZM11.776 4.00018H6.02405V1.93518H11.776V4.00018ZM6.99805 7.00018C7.27419 7.00018 7.49805 7.22404 7.49805 7.50018V15.5002C7.49805 15.7763 7.27419 16.0002 6.99805 16.0002C6.7219 16.0002 6.49805 15.7763 6.49805 15.5002V7.50018C6.49805 7.22404 6.7219 7.00018 6.99805 7.00018ZM10.998 7.00018C11.2742 7.00018 11.498 7.22404 11.498 7.50018V15.5002C11.498 15.7763 11.2742 16.0002 10.998 16.0002C10.7219 16.0002 10.498 15.7763 10.498 15.5002V7.50018C10.498 7.22404 10.7219 7.00018 10.998 7.00018Z"
        fill="#8996A2"
      />
    </svg>
  );
};

IconDelete.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconDelete.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconDelete;
